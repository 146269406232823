import { createI18n } from "vue-i18n";
import en from "./locales/en.json";
import de from "./locales/de.json";
import { getBrowserLocales } from "./helpers";

type MessageSchema = typeof en;

const browserLocales = getBrowserLocales({ languageCodeOnly: true });

export const selectedLocale = browserLocales ? browserLocales[0] : "en";
const i18n = createI18n<[MessageSchema], "en" | "de">({
  legacy: false,
  globalInjection: true,
  warnHtmlInMessage: "off",
  locale: localStorage.getItem("language") || selectedLocale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: {
    en: en,
    de: de,
  },
});

export { i18n };
