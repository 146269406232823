<template>
  <div class="fixed z-20 inset-0 overflow-y-auto">
    <div
      class="flex items-end justify-center min-h-screen p-0 text-center sm:block"
    >
      <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
      <div
        class="inline-block align-bottom bg-white px-4 pt-2 sm:p-5 rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-screen-md sm:w-1/3 h-screen w-screen sm:h-96"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="flex flex-col h-full">
          <div class="flex flex-row justify-between items-center px-1.5 py-1">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Changelogs
            </h3>
            <div class="flex flex-row justify-center items-center space-x-4">
              <PlusIcon
                @click.prevent="newChangelogPanelOpen = true"
                v-if="user.role === 'admin'"
                class="h-5 w-5 text-slate-400 hover:text-slate-500 cursor-pointer"
              />
              <XMarkIcon
                class="h-5 w-5 text-slate-400 hover:text-slate-500 cursor-pointer"
                @click.prevent="$emit('close')"
              />
            </div>
          </div>
          <ul class="mt-3 overflow-y-auto py-1 px-0.5">
            <li
              v-for="(log, logIndex) in changelogs"
              :key="logIndex"
              @click.prevent="toggleChangelogOpen(log)"
              class="mt-1.5 w-full flex flex-col cursor-pointer"
            >
              <div
                :class="[
                  log.open ? 'bg-slate-200' : 'bg-slate-100',
                  'flex flex-row justify-between items-center py-2 px-3 rounded-lg hover:bg-slate-200',
                ]"
              >
                <div class="font-medium">
                  <span
                    class="text-rooom-default hover:text-rooom-light font-medium"
                    >{{ log.version }}</span
                  ><span class="ml-2 text-slate-500 font-normal"
                    >({{ formatDateLocalChangelog(log.created_at) }})</span
                  >
                </div>
                <div class="flex flex-row jusify-center items-center space-x-3">
                  <button
                    v-if="user.role === 'admin'"
                    @click.stop.prevent="openEditChangelogPanel(log)"
                    class="focus:outline-none"
                  >
                    <PencilIcon
                      class="h-4 w-4 text-slate-400 hover:text-slate-500"
                    />
                  </button>
                  <div class="inline-flex items-center justify-center">
                    <button v-if="!log.open">
                      <ChevronDownIcon
                        class="h-4 w-4 text-slate-400 hover:text-slate-500"
                      />
                    </button>

                    <button v-else>
                      <ChevronUpIcon
                        class="h-4 w-4 text-slate-500 hover:text-slate-400"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-if="log.open"
                class="text-sm mt-1.5 pl-1 text-slate-600 rounded-md"
              >
                <Markdown
                  class="prose prose-sm prose-slate py-1 px-2"
                  :source="log.content"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <new-changelog-panel
    v-if="newChangelogPanelOpen"
    @added="onAdded"
    @close="newChangelogPanelOpen = false"
  />
  <edit-changelog-panel
    v-if="editChangelogPanelOpen"
    :changelog="editingChangelog"
    @updated="onUpdated"
    @close="editChangelogPanelOpen = false"
  />
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  XMarkIcon,
  PencilIcon,
} from "@heroicons/vue/20/solid";
import { PlusIcon } from "@heroicons/vue/24/outline";
import { formatDateLocalChangelog } from "@/helpers";
import Markdown from "vue3-markdown-it";
import NewChangelogPanel from "@/components/NewChangelogPanel.vue";
import EditChangelogPanel from "@/components/EditChangelogPanel.vue";

export default defineComponent({
  name: "AccountsSelectionPanel",
  components: {
    ChevronDownIcon,
    ChevronUpIcon,
    PencilIcon,
    XMarkIcon,
    Markdown,
    PlusIcon,
    NewChangelogPanel,
    EditChangelogPanel,
  },
  emits: ["close"],
  setup() {
    const store = useStore();
    const user = computed(() => store.state.auth.user);
    const changelogs = ref(<any>[]);

    function getChangelogs() {
      store.dispatch("auth/getChangelogs").then((response: any) => {
        changelogs.value = response.data.changelogs;
        store.commit("auth/setCurrentVersion", response.data.current_version);
      });
    }

    getChangelogs();

    function toggleChangelogOpen(log: any) {
      if (!log.open) {
        log.open = true;
      } else {
        log.open = false;
      }
    }

    function onAdded() {
      getChangelogs();
      newChangelogPanelOpen.value = false;
    }

    function onUpdated() {
      getChangelogs();
      editChangelogPanelOpen.value = false;
    }

    const newChangelogPanelOpen = ref(false);
    const editChangelogPanelOpen = ref(false);
    const editingChangelog = ref<any>();

    function openEditChangelogPanel(log: any) {
      editingChangelog.value = log;
      editChangelogPanelOpen.value = true;
    }
    return {
      changelogs,
      formatDateLocalChangelog,
      toggleChangelogOpen,
      newChangelogPanelOpen,
      onUpdated,
      openEditChangelogPanel,
      editChangelogPanelOpen,
      editingChangelog,
      user,
      onAdded,
    };
  },
});
</script>
