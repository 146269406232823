import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["data-tippy-content", "id"]
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = ["data-tippy-content", "id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.account && _ctx.account.email)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: "#",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
        "data-tippy-content": _ctx.account.email,
        id: `profile-icon-${_ctx.iconCount}`,
        class: _normalizeClass(_ctx.clickEnabled ? 'cursor-pointer' : 'pointer-events-none')
      }, [
        (
        (!_ctx.account.image && _ctx.refreshCount <= 0) ||
        (_ctx.imageError && _ctx.refreshCount <= 0) ||
        _ctx.limitInfo
      )
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass([
        _ctx.account.role === 'admin'
          ? 'bg-gray-600 ring-gray-400'
          : ['manager', 'default_manager'].includes(_ctx.account.role)
            ? 'bg-rooom-light ring-rooom-lightest'
            : _ctx.account.role === 'customer'
              ? 'bg-violet-500 ring-violet-300'
              : 'bg-amber-500 ring-amber-300',
        _ctx.size < 10 ? ' ring-2' : ' ring-4',
        _ctx.size === 8 ? 'w-7 h-7' : _ctx.size === 10 ? 'w-9 h-9' : 'w-11 h-11',
        'flex items-center justify-center rounded-full',
      ])
            }, [
              (_ctx.limitInfo)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass([
          _ctx.size < 10 ? 'text-sm' : _ctx.size < 12 ? 'text-base' : 'text-lg',
          'text-sm font-medium leading-none text-white uppercase',
        ])
                  }, _toDisplayString(_ctx.$t(_ctx.account.role)[0]), 3))
                : (_ctx.account.first_name)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      class: _normalizeClass([
          _ctx.size < 10 ? 'text-sm' : _ctx.size < 12 ? 'text-base' : 'text-lg',
          'text-sm font-medium leading-none text-white uppercase',
        ])
                    }, _toDisplayString(_ctx.account.first_name[0] + _ctx.account.last_name[0]), 3))
                  : _createCommentVNode("", true)
            ], 2))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              class: _normalizeClass([
        _ctx.account.role === 'admin'
          ? ' ring-gray-500'
          : ['manager', 'default_manager'].includes(_ctx.account.role)
            ? ' ring-rooom-lighter'
            : _ctx.account.role === 'customer'
              ? ' ring-violet-400'
              : ' ring-amber-400',
        _ctx.size < 10 ? ' ring-2' : ' ring-4',
        `h-${_ctx.size - 1} w-${_ctx.size - 1} max-w-none rounded-full object-cover`,
      ]),
              src: _ctx.imageSource.replace('http://', 'https://'),
              onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onImageError && _ctx.onImageError(...args))),
              alt: _ctx.account.last_name
            }, null, 42, _hoisted_2))
      ], 10, _hoisted_1))
    : (_openBlock(), _createElementBlock("a", {
        key: 1,
        href: "#",
        "data-tippy-content": _ctx.$t('deleted-account'),
        id: `profile-icon-${_ctx.iconCount}`
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([
        _ctx.size < 10 ? ' ring-2' : ' ring-4',
        `h-${_ctx.size - 1} w-${
          _ctx.size - 1
        } inline-flex items-center justify-center rounded-full bg-gray-700 ring-gray-900`,
      ])
        }, null, 2)
      ], 8, _hoisted_3))
}