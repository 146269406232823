import moment from "moment-timezone";

export function formatDateTime(timezone: string, datetime: string): string {
  let formatting = moment(datetime);
  if (timezone === "GER") {
    formatting = formatting.tz("Europe/Berlin").locale("de");
  } else {
    formatting = formatting.tz("UTC");
  }
  const formatted = formatting.format("DD MMM, YYYY HH:mm").toString();
  return formatted;
}

export function getBrowserLocales(options = {}) {
  const defaultOptions = {
    languageCodeOnly: false,
  };
  const opt = {
    ...defaultOptions,
    ...options,
  };
  const browserLocales =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages;
  if (!browserLocales) {
    return undefined;
  }
  return browserLocales.map((locale: string) => {
    const trimmedLocale = locale.trim();
    return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
  });
}

export function diffInDays(datetime: string, datetime2?: string): number {
  let compareDate = new Date(Date.now());
  if (datetime2) {
    compareDate = new Date(datetime2);
  }
  const tz = moment.tz.guess();
  const diffInMillis = moment(datetime).tz(tz).diff(compareDate);
  const diffInDays = Math.floor(diffInMillis / (1000 * 60 * 60 * 24));
  return diffInDays;
}

export function formatDateUTC(datetime: string, lang: string) {
  return moment(datetime).locale(lang).tz("UTC").format("DD MMM YYYY");
}

export function formatDateLocal(datetime: string, lang: string) {
  const tz = moment.tz.guess();
  return moment(datetime).locale(lang).tz(tz).format("DD MMM YYYY");
}

export function formatDateLocalChangelog(datetime: string) {
  const tz = moment.tz.guess();
  return moment(datetime).locale("en").tz(tz).format("YYYY-MM-DD");
}

export function formatDateTimeLocal(datetime: string) {
  const tz = moment.tz.guess();
  return moment(datetime).tz(tz).format("DD MMM, YYYY HH:mm").toString();
}

export function formatDateLocalChat(datetime: string) {
  const tz = moment.tz.guess();
  const result = moment(datetime).tz(tz).format("ddd DD MMM").toString();
  const now = new Date(Date.now());
  if (moment(datetime).isSame(now, "day")) {
    return "Today";
  } else {
    return result;
  }
}

export function formatTimeLocalChat(datetime: string) {
  const tz = moment.tz.guess();
  return moment(datetime).tz(tz).format("HH:mm").toString();
}

export function formatDateTimeUTC(datetime: string) {
  return moment(datetime).tz("UTC").format("DD MMM, YYYY HH:mm");
}

export function extractFileName(fileUrl: string) {
  const splitted = fileUrl.split("/");
  return splitted[splitted.length - 1];
}

export function isPast(theDate: string) {
  const date = new Date(theDate);
  const now = new Date(Date.now());
  // subscription does not expire on the same day
  if (moment(date).isSame(now, "day")) {
    return false;
  } else {
    if (diffInDays(date.toISOString()) > 0) {
      return false;
    } else {
      return true;
    }
  }
}

export function isSameDay(theDate: string) {
  const date = new Date(theDate);
  const now = new Date(Date.now());
  // subscription does not expire on the same day
  if (moment(date).isSame(now, "day")) {
    return true;
  } else {
    return false;
  }
}

export function formatEuro(budget: string | number) {
  if (!budget) {
    return undefined;
  }
  if (typeof budget === "string") {
    budget = parseFloat(budget);
  }
  const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  });
  return formatter.format(budget); /* $2,500.00 */
}

export function isUriImage(uri: string) {
  //make sure we remove any nasty GET params
  uri = uri.split("?")[0];
  //moving on, split the uri into parts that had dots before them
  const parts = uri.split(".");
  //get the last part ( should be the extension )
  const extension = parts[parts.length - 1].toLowerCase();
  //define some image types to test against
  const imageTypes = ["jpg", "jpeg", "tiff", "png", "gif", "bmp"];
  //check if the extension matches anything in the list.
  if (imageTypes.indexOf(extension) !== -1) {
    return true;
  }
}

export function isUrl(str: string) {
  let url;

  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export function linkify(text: string) {
  /* eslint-disable */
  const urlRegex =
    /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
  return text.replace(urlRegex, function (url) {
    let href = url;
    if (!url.startsWith("http")) {
      href = "https://" + url;
    }
    return (
      '<a target="_blank" href="' +
      href +
      '" style="text-decoration: underline; text-decoration-color: #4B5563;">' +
      url +
      "</a>"
    );
  });
}
