import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import LoginPage from "../views/LoginPage.vue";
import LoggedInLayout from "../components/LoggedInLayout.vue";
import { store } from "../store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    redirect: "/login",
    meta: {
      title: "rooom Case Manager",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    meta: { disallowAuthed: true, title: "rooom Case Manager" },
  },
  {
    path: "/register",
    name: "PreRegister",
    component: () => import("../views/RegisterPage.vue"),
    meta: {
      title: "Register | rooom Case Manager",
    },
  },
  {
    path: "/register/:role",
    name: "Register",
    component: () => import("../views/RegisterPage.vue"),
    meta: {
      title: "Register | rooom Case Manager",
    },
  },
  {
    path: "/register_confirm/:token",
    component: () => import("../views/ConfirmRegistrationPage.vue"),
    meta: {
      title: "Confirm your registration | rooom Case Manager",
    },
  },
  {
    path: "/reset-password/:token",
    name: "ResetPassword",
    meta: { title: "Reset Password | rooom Case Manager" },
    component: () => import("../views/PasswordResetPage.vue"),
  },
  {
    path: "/order",
    name: "NewOrderPopup",
    meta: { title: "New Order | rooom Case Manager" },
    component: () => import("../views/NewOrderPopup.vue"),
  },
  {
    path: "/order_details/:orderToken",
    name: "OrderOverviewPopup",
    meta: { title: "Order | rooom Case Manager" },
    component: () => import("../views/OrderOverviewPopup.vue"),
  },
  {
    name: "PrivacyNonUser",
    path: "/privacy",
    meta: { title: "Privacy Policy | rooom Case Manager" },
    component: () => import("@/views/PrivacyPage.vue"),
  },
  {
    name: "TermsAndConditionsNonUser",
    path: "/terms",
    meta: { title: "Terms and Conditions | rooom Case Manager" },
    component: () => import("@/views/TermsPage.vue"),
  },
  {
    name: "ImpressumNonUser",
    path: "/impressum",
    meta: { title: "Impressum | rooom Case Manager" },
    component: () => import("@/views/ImpressumPage.vue"),
  },
  {
    name: "DisclaimerNonUser",
    path: "/disclaimer",
    meta: { title: "Disclaimer | rooom Case Manager" },
    component: () => import("@/views/DisclaimerPage.vue"),
  },
  {
    path: "/user",
    component: LoggedInLayout,
    meta: { requiresAuth: true, title: "rooom Case Manager" },
    children: [
      {
        name: "Orders",
        path: "orders",
        alias: "",
        component: () => import("../views/OrdersList.vue"),
        meta: {
          title: "Orders | rooom Case Manager",
        },
      },
      {
        name: "Accounts",
        path: "accounts",
        component: () => import("../views/AccountsList.vue"),
        meta: {
          title: "Accounts | rooom Case Manager",
        },
      },
      {
        name: "Groups",
        path: "groups",
        component: () => import("../views/GroupsList.vue"),
        meta: {
          title: "Groups | rooom Case Manager",
        },
      },
      {
        name: "Account",
        path: "accounts/:accountId",
        component: () => import("../views/AccountPage.vue"),
        meta: {
          title: "Account Details | rooom Case Manager",
        },
      },
      {
        name: "Group",
        path: "groups/:groupName",
        component: () => import("../views/GroupPage.vue"),
        meta: {
          title: "Group Details | rooom Case Manager",
        },
      },
      {
        name: "Settings",
        path: "settings",
        component: () => import("../views/SettingsPage.vue"),
        meta: {
          title: "Settings | rooom Case Manager",
        },
      },
      {
        name: "Order",
        path: "orders/:orderToken",
        component: () => import("../views/order-page/OrderPage.vue"),
        meta: {
          title: "Order | rooom Case Manager",
        },
      },
      {
        name: "New Order",
        path: "order",
        component: () => import("../views/NewOrderPage.vue"),
        meta: {
          title: "New Order | rooom Case Manager",
        },
      },
      {
        name: "Privacy",
        path: "privacy",
        meta: { title: "Privacy Policy | rooom Case Manager" },
        component: () => import("@/views/PrivacyPage.vue"),
      },
      {
        name: "TermsAndConditions",
        path: "terms",
        meta: { title: "Terms and Conditions | rooom Case Manager" },
        component: () => import("@/views/TermsPage.vue"),
      },
      {
        name: "Impressum",
        path: "impressum",
        meta: { title: "Impressum | rooom Case Manager" },
        component: () => import("@/views/ImpressumPage.vue"),
      },
      {
        name: "Disclaimer",
        path: "disclaimer",
        meta: { title: "Disclaimer | rooom Case Manager" },
        component: () => import("@/views/DisclaimerPage.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
    ? (to.meta.title as string)
    : "rooom Case Manager";
  // auth guard
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters["auth/loggedIn"]) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      store.dispatch("setCurrentPage", to.name);
      next();
    }
    // redirect to user page when logged in
  } else if (to.matched.some((record) => record.meta.disallowAuthed)) {
    if (store.getters["auth/loggedIn"]) {
      next({
        path: "/user",
      });
    } else {
      next();
    }
    // next() should always be called
  } else {
    next();
  }
});

export default router;
