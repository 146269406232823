import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass([
      _ctx.role === 'admin' && !_ctx.darkMode
        ? 'bg-gray-600 text-white'
        : _ctx.role === 'admin' && _ctx.darkMode
          ? 'bg-gray-100 text-gray-600'
          : ['manager', 'default_manager'].includes(_ctx.role) && !_ctx.darkMode
            ? 'bg-rooom-light text-white'
            : ['manager', 'default_manager'].includes(_ctx.role) && _ctx.darkMode
              ? 'bg-rooom-lightest text-rooom-default'
              : _ctx.role === 'partner' && !_ctx.darkMode
                ? 'bg-amber-500 text-white'
                : _ctx.role === 'partner' && _ctx.darkMode
                  ? 'bg-amber-100 text-amber-500'
                  : _ctx.role === 'customer' && !_ctx.darkMode
                    ? 'bg-violet-500 text-white'
                    : 'bg-violet-100 text-violet-500',
      'whitespace-nowrap inline-flex items-center px-2 py-0.5 rounded-full text-xs font-semibold',
    ])
  }, _toDisplayString(_ctx.role === "default_manager" ? "default manager" : _ctx.$t(_ctx.role)), 3))
}