<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
      <div
        class="inline-block align-bottom bg-white px-4 pt-5 pb-4 sm:p-8 rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-96"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <h3 class="text-lg leading-6 font-medium text-gray-900 mb-6">
          {{ $t("new-group") }}
        </h3>
        <form
          enctype="multipart/form-data"
          class="space-y-6"
          @submit.prevent="onSubmit"
        >
          <div>
            <label for="name" class="block text-sm font-medium text-gray-700">
              {{ $t("name") }}
            </label>
            <div class="mt-1 relative">
              <input
                v-model="name"
                name="name"
                type="name"
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-rooom-light focus:border-rooom-light sm:text-sm"
              />
              <div
                v-if="nameError"
                class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
              >
                <ExclamationCircleIcon class="h-5 w-5 text-red-500" />
              </div>
            </div>
            <p class="pl-1 mt-2 text-sm text-red-600">
              {{ nameError }}
            </p>
          </div>

          <div class="flex flex-row space-x-5">
            <div>
              <label
                for="color"
                class="block text-sm font-medium text-gray-700"
              >
                {{ $t("color") }}
              </label>
              <div
                class="mt-1 relative flex flex-row justify-center items-center space-x-2"
              >
                <input
                  type="color"
                  id="color"
                  name="color"
                  v-model="color"
                  @change="handleColorChange"
                />
                <span
                  class="text-sm font-medium text-gray-600 tracking-wider"
                  >{{ color }}</span
                >
              </div>
            </div>
          </div>

          <div>
            <div class="flex flex-row justify-between items-end">
              <label
                for="members"
                class="block text-sm font-medium text-gray-700"
              >
                {{ $t("members") }}
              </label>
            </div>
            <div class="mt-1 relative">
              <div class="relative flex items-stretch grow focus-within:z-20">
                <div
                  class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                >
                  <UserPlusIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="members"
                  id="search-member"
                  v-model="memnberSearchInput"
                  @focus="searchFocused = true"
                  @blur="onInputBlur"
                  class="focus:ring-rooom-light focus:border-rooom-light focus:outline-none block w-full rounded-md pl-10 sm:text-sm border-gray-300 appearance-none placeholder-gray-400 px-3 py-2 shadow-sm"
                  :placeholder="$t('add-a-member')"
                />
              </div>
              <!-- search dropbox -->
              <transition
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <ul
                  v-if="searchFocused"
                  class="absolute z-40 mt-30 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                >
                  <!--
        Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

        Highlighted: "text-white bg-indigo-600", Not Highlighted: "text-gray-900"
      -->
                  <li
                    @click.prevent="toggleSelectedMember(account)"
                    v-for="(account, accountIdx) in filteredAccounts"
                    :key="accountIdx"
                    class="cursor-pointer text-gray-900 select-none relative py-2 pl-3 pr-9 hover:bg-rooom-lightest"
                    id="listbox-option-0"
                    role="option"
                  >
                    <div class="flex items-center">
                      <profile-icon
                        :account="account"
                        :darkMode="false"
                        :clickEnabled="false"
                        :showTooltip="false"
                        :size="10"
                      />
                      <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                      <div class="ml-3 flex flex-col">
                        <span class="text-sm font-medium text-gray-900 truncate"
                          >{{ account.first_name }}
                          {{ account.last_name }}</span
                        >
                        <span class="text-sm text-gray-500 truncate">{{
                          account.email
                        }}</span>
                      </div>
                    </div>

                    <!--
          Checkmark, only display for selected option.

          Highlighted: "text-white", Not Highlighted: "text-indigo-600"
        -->
                    <span
                      v-if="
                        members.find((member) => member.email === account.email)
                      "
                      class="text-rooom-light absolute inset-y-0 right-0 flex items-center pr-4"
                    >
                      <CheckIcon class="h-5 w-5" />
                    </span>
                  </li>
                </ul>
              </transition>

              <ul
                class="mt-2 px-2 border-gray-200 divide-y divide-gray-200 overflow-y-auto max-h-52"
              >
                <li
                  v-for="(member, memberIdx) in members"
                  :key="memberIdx"
                  class="py-3 flex justify-between items-center flex-wrap"
                >
                  <div class="flex items-center grow">
                    <profile-icon
                      :account="member"
                      :darkMode="false"
                      :clickEnabled="false"
                      :showTooltip="false"
                      :size="10"
                    />
                    <div class="ml-3 flex flex-col whitespace-nowrap">
                      <span class="text-sm font-medium text-gray-900"
                        >{{ member.first_name }}
                        {{ member.last_name }}
                      </span>
                      <span class="text-sm text-gray-500 truncate">{{
                        member.email
                      }}</span>
                    </div>
                  </div>
                  <button
                    @click.prevent="removeMember(memberIdx)"
                    type="button"
                    class="ml-6 bg-white rounded-md text-sm font-medium text-rooom-default hover:text-rooom-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rooom-light"
                  >
                    <XMarkIcon class="h-6 w-6" />
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="flex flex-row space-x-5">
            <button
              :disabled="name === ''"
              type="submit"
              class="disabled:cursor-not-allowed disabled:opacity-50 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-rooom-default hover:bg-rooom-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rooom-light"
            >
              {{ $t("create") }}
            </button>
            <button
              @click.prevent="close"
              class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              {{ $t("cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed } from "vue";
import { useStore } from "vuex";
import {
  ExclamationCircleIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/vue/20/solid";
import { UserPlusIcon } from "@heroicons/vue/24/outline";
import ProfileIcon from "@/components/ProfileIcon.vue";

export default defineComponent({
  name: "GroupCreationPanel",
  components: {
    UserPlusIcon,
    ProfileIcon,
    ExclamationCircleIcon,
    CheckIcon,
    XMarkIcon,
  },
  props: {
    propName: {
      type: String,
      default: "",
    },
    propColor: {
      type: String,
      default: "#3b99a2",
    },
    propMembers: {
      type: <any>Array,
      default: [],
    },
  },
  emits: ["closeCreateGroupPanel"],
  setup(props) {
    const store = useStore();
    const selectedUserIdx = ref(1);
    const accounts = computed(() => {
      return store.state.accounts.accounts;
    });
    const members = ref(props.propMembers);
    const name = ref(props.propName);
    const nameError = ref("");
    const color = ref(props.propColor);
    const searchFocused = ref(false);

    watch(nameError, (newNameError) => {
      if (newNameError !== "") {
        document.querySelector('input[name="name"]')?.classList.add("error");
      } else {
        document.querySelector('input[name="name"]')?.classList.remove("error");
      }
    });

    async function getAccounts() {
      const params = {
        query: "",
        sort: "modified",
        filterOut: "admin",
      };
      store.dispatch("accounts/getAccounts", params);
    }

    function close() {
      store.commit("closeCreateGroupPanel");
      reset();
    }

    function reset() {
      name.value = "";
      color.value = "#3b99a2";
      members.value = [];
      nameError.value = "";
    }

    getAccounts();

    function onSubmit() {
      const payload = new FormData();
      payload.append("name", name.value);
      payload.append("color", color.value);
      members.value.forEach((member: any) => {
        payload.append("members", member.email);
      });
      store.dispatch("groups/createGroup", payload).then(() => {
        store.dispatch("successOnCreateGroupPanel", "group-create-alert-200");
      });
    }

    function removeMember(memberIdx: number) {
      members.value.splice(memberIdx, 1);
    }

    function toggleSelectedMember(account: any) {
      const memberIdx = members.value.findIndex(
        (member: any) => member.email === account.email,
      );
      if (memberIdx > -1) {
        removeMember(memberIdx);
      } else {
        members.value.push(account);
      }
    }

    const memnberSearchInput = ref("");
    const filteredAccounts = ref([...accounts.value]);

    watch(accounts, (newAccounts) => {
      if (memnberSearchInput.value) {
        filteredAccounts.value = [
          ...newAccounts.filter((account: any) => {
            if (
              account.first_name
                .toLowerCase()
                .includes(memnberSearchInput.value.toLowerCase())
            ) {
              return true;
            } else if (
              account.last_name
                .toLowerCase()
                .includes(memnberSearchInput.value.toLowerCase())
            ) {
              return true;
            } else if (
              account.email
                .toLowerCase()
                .includes(memnberSearchInput.value.toLowerCase())
            ) {
              return true;
            }
          }),
        ];
      } else {
        filteredAccounts.value = [...newAccounts];
      }
    });

    watch(memnberSearchInput, (newInput) => {
      filteredAccounts.value = [
        ...accounts.value.filter((account: any) => {
          if (
            account.first_name.toLowerCase().includes(newInput.toLowerCase())
          ) {
            return true;
          } else if (
            account.last_name.toLowerCase().includes(newInput.toLowerCase())
          ) {
            return true;
          } else if (
            account.email.toLowerCase().includes(newInput.toLowerCase())
          ) {
            return true;
          }
        }),
      ];
    });

    function onInputBlur() {
      setTimeout(() => (searchFocused.value = false), 300);
    }

    return {
      selectedUserIdx,
      accounts,
      name,
      nameError,
      color,
      onSubmit,
      members,
      removeMember,
      searchFocused,
      toggleSelectedMember,
      close,
      memnberSearchInput,
      filteredAccounts,
      onInputBlur,
    };
  },
});
</script>

<style lang="scss" scoped>
input.error {
  @apply border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500;
}

#drop-box.dragged-over {
  @apply bg-gray-300 bg-opacity-50;
}

#color {
  @apply border-2 border-dashed border-gray-300 p-0.5 rounded-full w-9 h-9 outline-none cursor-pointer;
  -webkit-appearance: none;
}

#color::-webkit-color-swatch-wrapper {
  padding: 0;
}
#color::-webkit-color-swatch {
  border: none;
  border-radius: 100%;
}
</style>
