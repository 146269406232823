import { InjectionKey } from "vue";
import {
  createStore,
  createLogger,
  Store,
  useStore as baseUseStore,
} from "vuex";
import auth from "./modules/auth.module";
import orders from "./modules/orders.module";
import groups from "./modules/groups.module";
import accounts from "./modules/accounts.module";
import moment from "moment-timezone";
import { ErrorWithCallbackAction } from "@/types/common.interface";
import { selectedLocale } from "@/i18n";

// define your typings for the store state
export interface RootState {
  profileIconCount: number;
  currentPage: string;
  currentLanguage: string;
  paymentMode: string;
  alert: {
    open: boolean;
    closeButtonExist: boolean;
    disappearAfter: number;
    type: string;
    message: string;
  };
  panel: {
    open: boolean;
    message: string;
    yesMessage: string;
    noMessage: string;
    result: string;
    targetType: string;
    input: any;
    inputType: string;
  };
  createGroupPanel: {
    open: boolean;
    name: string;
    color: string;
    members: Array<any>;
    result: string;
  };
  panels: {
    selectGroupsPanel: {
      open: boolean;
      targetType: string;
      targets: Array<any>;
      result: string;
    };
    selectAccountsPanel: {
      open: boolean;
      targetType: string;
      targets: Array<any>;
      result: string;
    };
  };
  banner: {
    open: boolean;
    type: string;
    mainText: string;
    shortText: string;
    linkText: string;
    link: string;
  };
}

// define injection key
export const key: InjectionKey<Store<RootState>> = Symbol();

export const store = createStore<RootState>({
  state: {
    profileIconCount: 0,
    currentPage: "Orders",
    currentLanguage: selectedLocale,
    paymentMode: "off-session",
    alert: {
      open: false,
      closeButtonExist: true,
      disappearAfter: 3000,
      type: "error",
      message: "",
    },
    panel: {
      open: false,
      message: "",
      yesMessage: "Yes",
      noMessage: "No",
      result: "yet",
      targetType: "",
      input: "",
      inputType: "",
    },
    createGroupPanel: {
      open: false,
      name: "",
      color: "#3b99a2",
      members: [],
      result: "yet",
    },
    panels: {
      selectGroupsPanel: {
        open: false,
        result: "yet",
        targetType: "",
        targets: [],
      },
      selectAccountsPanel: {
        open: false,
        result: "yet",
        targetType: "",
        targets: [],
      },
    },
    banner: {
      open: false,
      type: "error",
      mainText: "",
      shortText: "",
      linkText: "",
      link: "",
    },
  },
  mutations: {
    incrementProfileIconCount(state) {
      state.profileIconCount++;
    },
    setCurrentPage(state, page: string) {
      state.currentPage = page;
    },
    setCurrentLanguage(state, language: string) {
      state.currentLanguage = language.toLowerCase();
      localStorage.setItem("language", language.toLowerCase());
    },
    setPaymentMode(state, value: string) {
      state.paymentMode = value;
    },
    openAlert(
      state,
      payload: {
        type: string;
        message: string;
        disappearAfter?: number;
        closeButtonExist?: boolean;
      },
    ) {
      state.alert.open = true;
      state.alert.type = payload.type;
      state.alert.message = payload.message;
      if (payload.disappearAfter)
        state.alert.disappearAfter = payload.disappearAfter;
      if (payload.closeButtonExist)
        state.alert.closeButtonExist = payload.closeButtonExist;
    },
    closeAlert(state) {
      state.alert.open = false;
      // reset default values
      state.alert.closeButtonExist = true;
      state.alert.disappearAfter = 3000;
    },
    openPanel(
      state,
      payload: {
        message: string;
        yesMessage: string;
        noMessage: string;
        targetType: string;
        input: any;
        inputType: string;
      },
    ) {
      state.panel.open = true;
      state.panel.message = payload.message;
      state.panel.yesMessage = payload.yesMessage;
      state.panel.noMessage = payload.noMessage;
      state.panel.targetType = payload.targetType;
      if (payload.inputType) state.panel.inputType = payload.inputType;
      if (payload.input) state.panel.input = payload.input;
      state.panel.result = "yet";
    },
    noClickedOnPanel(state) {
      state.panel.open = false;
      state.panel.result = "fail";
      state.panel.input = "";
      state.panel.inputType = "";
    },
    yesClickedOnPanel(state) {
      state.panel.open = false;
      state.panel.result = "success";
    },
    resetPanel(state) {
      state.panel.input = "";
      state.panel.inputType = "";
    },
    openCreateGroupPanel(
      state,
      payload: { name: string; color: string; members: Array<any> },
    ) {
      state.createGroupPanel.open = true;
      state.createGroupPanel.name = payload.name;
      state.createGroupPanel.color = payload.color;
      state.createGroupPanel.members = payload.members;
      state.panel.result = "yet";
    },
    closeCreateGroupPanel(state) {
      state.createGroupPanel.open = false;
    },
    setPanelInput(state, newInput) {
      state.panel.input = newInput;
    },
    openBanner(state, payload: RootState["banner"]) {
      if (localStorage.getItem("bannerClosed") !== "true") {
        state.banner.open = true;
        state.banner.type = payload.type;
        state.banner.mainText = payload.mainText;
        state.banner.shortText = payload.shortText;
        state.banner.linkText = payload.linkText;
        state.banner.link = payload.link;
      }
    },
    closeBanner(state, closedByUser: boolean) {
      state.banner.open = false;
      localStorage.setItem("bannerClosed", closedByUser.toString());
    },
  },
  actions: {
    setCurrentPage({ commit, rootGetters }, page: string) {
      commit("setCurrentPage", page);
      const user = rootGetters["auth/user"];
      if (user && ["pending", "approving"].includes(user.status)) {
        commit(
          "openBanner",
          {
            type: user.status === "pending" ? "error" : "warning",
            mainText: `${user.status}-banner-message-main`,
            shortText: `${user.status}-banner-message-short`,
            linkText: user.status === "pending" ? "go-to-settings" : "",
            link: user.status === "pending" ? "/user/settings" : "",
          },
          { root: true },
        );
      } else {
        commit("closeBanner", false, { root: true });
      }
    },
    handleError({ commit, dispatch }, payload: ErrorWithCallbackAction) {
      return new Promise((resolve, reject) => {
        if (payload.error.response?.status === 401) {
          if ((payload.error.response.data as any).header !== "Deleted User") {
            dispatch("auth/refresh", { root: true })
              .then((refreshed) => {
                if (refreshed) {
                  if (payload.callbackAction) {
                    if (payload.callbackActionArgs) {
                      dispatch(
                        payload.callbackAction,
                        payload.callbackActionArgs,
                      ).then((response: any) => {
                        resolve(response);
                      });
                    } else {
                      dispatch(payload.callbackAction).then((response: any) => {
                        resolve(response);
                      });
                    }
                  }
                }
              })
              .catch((error) => {
                dispatch("auth/logout", { root: true });
              });
          } else {
            dispatch("auth/logout", { root: true });
          }
        } else {
          // hide the unexpected server errors
          if (!payload.error.response?.status.toString().includes("50")) {
            commit("openAlert", {
              type: "error",
              message: `alerts.${
                payload.callbackAction
              }.${payload.error.response?.status.toString()}`,
              disappearAfter: 3000,
              closeButtonExist: true,
            });
          }
        }
      });
    },
    formatDateTimeLocal(_, datetime: string) {
      return moment(datetime)
        .tz("Europe/Berlin")
        .format("DD MMM, YYYY HH:mm")
        .toString();
    },
    formatDateTimeUTC(_, datetime: string) {
      return moment(datetime).tz("UTC").format("DD MMM, YYYY HH:mm");
    },
    successOnCreateGroupPanel({ commit, state }, message: string) {
      state.createGroupPanel.result = "success";
      state.createGroupPanel.open = false;
      commit("openAlert", {
        type: "success",
        message: message,
        disappearAfter: 3000,
        closeButtonExist: false,
      });
    },
    openPanel({ state }, results) {
      if (results.panelType === "selectGroups") {
        state.panels.selectGroupsPanel.open = true;
        state.panels.selectGroupsPanel.targetType = results.targetType;
        state.panels.selectGroupsPanel.targets = results.targets;
        state.panels.selectGroupsPanel.result = "yet";
      } else if (results.panelType === "selectAccounts") {
        state.panels.selectAccountsPanel.open = true;
        state.panels.selectAccountsPanel.targetType = results.targetType;
        state.panels.selectAccountsPanel.targets = results.targets;
        state.panels.selectAccountsPanel.result = "yet";
      }
    },
    closePanel({ state }, panelType: string) {
      if (panelType === "selectGroups") {
        state.panels.selectGroupsPanel.open = false;
      } else if (panelType === "selectAccounts") {
        state.panels.selectAccountsPanel.open = false;
      }
    },
    setPanelInput({ state }, input: any) {
      state.panel.input = input;
    },
    submitOnPanel(
      { state, dispatch },
      results: { panelType: string; result: any },
    ) {
      if (results.panelType === "selectGroups") {
        if (state.panels.selectGroupsPanel.targetType === "orders") {
          const payload = {
            orderTokens: state.panels.selectGroupsPanel.targets,
            groupNames: results.result,
          };
          dispatch("groups/addOrdersToGroups", payload)
            .then(() => {
              state.panels.selectGroupsPanel.result = "success";
              dispatch("closePanel", "selectGroups");
            })
            .catch((error: any) => {
              state.panels.selectGroupsPanel.result = "error";
            });
        } else if (state.panels.selectGroupsPanel.targetType === "accounts") {
          const payload = {
            accountEmails: state.panels.selectGroupsPanel.targets,
            groupNames: results.result,
          };
          dispatch("groups/addMembersToGroups", payload)
            .then(() => {
              state.panels.selectGroupsPanel.result = "success";
              dispatch("closePanel", "selectGroups");
            })
            .catch((error: any) => {
              state.panels.selectGroupsPanel.result = "error";
            });
        }
      } else if (results.panelType === "selectAccounts") {
        if (state.panels.selectAccountsPanel.targetType === "groups") {
          const payload = {
            groupNames: state.panels.selectAccountsPanel.targets,
            accountEmails: results.result,
          };
          dispatch("groups/addMembersToGroups", payload)
            .then(() => {
              state.panels.selectAccountsPanel.result = "success";
              dispatch("closePanel", "selectAccounts");
            })
            .catch((error: any) => {
              state.panels.selectAccountsPanel.result = "error";
            });
        }
      }
    },
  },
  getters: {
    currentLanguage(state) {
      return state.currentLanguage;
    },
  },
  modules: {
    auth,
    orders,
    groups,
    accounts,
  },
  // strict: process.env.NODE_ENV !== "production",
  plugins: process.env.NODE_ENV === "development" ? [createLogger()] : [],
});

// define your own `useStore` composition function
export function useStore() {
  return baseUseStore(key);
}
