import { Module } from "vuex";
import { RootState } from "@/store/index";
import axios from "axios";

const API_URL = process.env.VUE_APP_BACKEND_URL + "/";

interface Orders {
  orders: any[];
  filters: any;
  filterOptionsViewMode: string;
  orderToken: undefined | string;
  status: undefined | string;
  memberAssignmentPanel: {
    targets: Array<string>;
  };
  orderUpdatePanel: {
    open: boolean;
    orderToken: string;
    message: string;
    yesMessage: string;
    prefilledValue: string | string[];
    field: string;
    maxLength: number;
  };
}

const orders: Module<Orders, RootState> = {
  namespaced: true,
  state: {
    orders: [],
    filters: { status: [], tags: [], members: [] },
    filterOptionsViewMode: "invisible",
    orderToken: undefined,
    status: undefined,
    memberAssignmentPanel: {
      targets: [],
    },
    orderUpdatePanel: {
      open: false,
      orderToken: "",
      message: "",
      yesMessage: "Update",
      prefilledValue: "",
      field: "",
      maxLength: 0,
    },
  },
  mutations: {
    setOrders(state, orders: any[]) {
      state.orders = orders;
    },
    setFilters(state, filters: any) {
      state.filters = filters;
    },
    setFilterOptionsViewMode(state, mode: string) {
      state.filterOptionsViewMode = mode;
    },
    setOrderToken(state, orderToken: string) {
      state.orderToken = orderToken;
    },
    setStatus(state, status: string) {
      state.status = status;
    },
    setMemberAssignmentTargets(state, targets: string[]) {
      state.memberAssignmentPanel.targets = targets;
    },
    openOrderUpdatePanel(state, payload: any) {
      state.orderUpdatePanel.field = payload.field;
      state.orderUpdatePanel.orderToken = payload.orderToken;
      state.orderUpdatePanel.message = payload.message;
      state.orderUpdatePanel.yesMessage = payload.yesMessage;
      state.orderUpdatePanel.prefilledValue = payload.prefilledValue;
      state.orderUpdatePanel.maxLength = payload.maxLength;
      state.orderUpdatePanel.open = true;
    },
    closeOrderUpdatePanel(state) {
      state.orderUpdatePanel.open = false;
      state.orderUpdatePanel.prefilledValue = "";
    },
  },
  actions: {
    getOrders({ state, dispatch, rootGetters }, params: any) {
      return axios
        .post(API_URL + "orders", params, {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          state.orders = response.data.orders;
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/getOrders",
              callbackActionArgs: params,
            },
            { root: true },
          );
        });
    },
    deleteOrders({ commit, dispatch, rootGetters }, orderTokens: string[]) {
      return axios
        .delete(API_URL + "orders", {
          headers: rootGetters["auth/authHeader"],
          data: { orderTokens: orderTokens },
        })
        .then(() => {
          commit(
            "openAlert",
            {
              type: "success",
              message: "alerts.orders/deleteOrders.200",
              disappearAfter: 3000,
              closeButtonExist: false,
            },
            { root: true },
          );
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/deleteOrders",
              callbackActionArgs: orderTokens,
            },
            { root: true },
          );
        });
    },
    updateMembersInOrders(
      { commit, dispatch, rootGetters },
      payload: {
        orderTokens: string[];
        accountIds?: number[];
        action?: string;
      },
    ) {
      return axios
        .put(API_URL + `orders`, payload, {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          commit(
            "openAlert",
            {
              type: "success",
              message: "alerts.orders/updateMembersInOrders.200",
              disappearAfter: 3000,
              closeButtonExist: false,
            },
            { root: true },
          );
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/updateMembersInOrders",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
    getOrder({ dispatch, rootGetters }, token: string) {
      return axios
        .get(API_URL + `order/${token}`, {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/getOrder",
              callbackActionArgs: token,
            },
            { root: true },
          );
        });
    },
    removeGroupFromOrder({ commit, dispatch, rootGetters }, payload: any) {
      return axios
        .delete(API_URL + "orders/group", {
          headers: rootGetters["auth/authHeader"],
          data: payload,
        })
        .then(() => {
          commit(
            "openAlert",
            {
              type: "success",
              message: "alerts.orders/removeGroupFromOrder.200",
              disappearAfter: 3000,
              closeButtonExist: false,
            },
            { root: true },
          );
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/removeGroupFromOrder",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
    removeMemberFromOrder({ commit, dispatch, rootGetters }, payload: any) {
      return axios
        .delete(API_URL + `order/${payload.orderToken}`, {
          headers: rootGetters["auth/authHeader"],
          data: payload,
        })
        .then(() => {
          commit(
            "openAlert",
            {
              type: "success",
              message: "alerts.orders/removeMemberFromOrder.200",
              disappearAfter: 3000,
              closeButtonExist: false,
            },
            { root: true },
          );
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/removeMemberFromOrder",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
    uploadModel({ commit, dispatch, rootGetters }, payload: any) {
      return axios
        .put(API_URL + `order/${payload.orderToken}/model`, payload.payload, {
          headers: rootGetters["auth/authHeader"],
        })
        .then(() => {
          commit(
            "openAlert",
            {
              type: "success",
              message: "alerts.orders/uploadModel.200",
              disappearAfter: 3000,
              closeButtonExist: false,
            },
            { root: true },
          );
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/uploadModel",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
    getPaymentInfo({ dispatch, rootGetters }, token: string) {
      return axios
        .get(API_URL + `order/${token}/payment`, {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/getPaymentInfo",
              callbackActionArgs: token,
            },
            { root: true },
          );
        });
    },
    createCheckoutSession({ dispatch, rootGetters }, token: string) {
      return axios
        .post(API_URL + `order/${token}/payment`, {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/createCheckoutSession",
              callbackActionArgs: token,
            },
            { root: true },
          );
        });
    },
    createCheckoutSessionAtRooomApp({ dispatch, rootGetters }, token: string) {
      return axios
        .post(API_URL + `order/rooom_app/${token}/payment`, {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/createCheckoutSessionAtRooomApp",
              callbackActionArgs: token,
            },
            { root: true },
          );
        });
    },
    processPayment({ dispatch, rootGetters }, token: string) {
      return axios
        .post(API_URL + `order/${token}/payment/process`, {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/processPayment",
              callbackActionArgs: token,
            },
            { root: true },
          );
        });
    },
    updateOrderStatus({ dispatch, rootGetters }, payload: any) {
      return axios
        .put(API_URL + `order/${payload.orderToken}`, payload.payload, {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/updateOrderStatus",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
    submitOffer({ dispatch, rootGetters }, payload: any) {
      return axios
        .post(API_URL + `order/${payload.orderToken}/offer`, payload.payload, {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/submitOffer",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
    updateOffer({ dispatch, rootGetters }, payload: any) {
      return axios
        .put(API_URL + `order/${payload.orderToken}/offer`, payload.formData, {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/submitOffer",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
    removeOffer({ dispatch, rootGetters }, orderToken: string) {
      return axios
        .delete(API_URL + `order/${orderToken}/offer`, {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/removeOffer",
              callbackActionArgs: orderToken,
            },
            { root: true },
          );
        });
    },
    addProduct({ dispatch, rootGetters }, payload: any) {
      return axios
        .post(
          API_URL + `order/${payload.orderToken}/product`,
          payload.payload,
          {
            headers: rootGetters["auth/authHeader"],
          },
        )
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/addProduct",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
    deleteProduct({ dispatch, rootGetters }, payload: any) {
      return axios
        .delete(API_URL + `order/${payload.orderToken}/product`, {
          headers: rootGetters["auth/authHeader"],
          data: payload.productId,
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/deleteProduct",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
    updateProduct({ dispatch, rootGetters }, payload: any) {
      return axios
        .put(API_URL + `order/${payload.orderToken}/product`, payload.product, {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/updateProduct",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
    submitComment({ dispatch, rootGetters }, payload: any) {
      return axios
        .post(
          API_URL + `order/${payload.orderToken}/comments`,
          payload.payload,
          {
            headers: rootGetters["auth/authHeader"],
          },
        )
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/submitComment",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
    deleteComment({ dispatch, rootGetters }, payload: any) {
      return axios
        .delete(API_URL + `order/${payload.orderToken}/comments`, {
          headers: rootGetters["auth/authHeader"],
          data: payload.payload,
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/deleteComment",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
    placeOrder({ commit, dispatch, rootGetters }, payload: any) {
      return axios
        .post(API_URL + `order/${payload.method}`, payload.formData, {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          console.dir(error);
          if (error?.response?.status === 406) {
            commit(
              "openAlert",
              {
                type: "error",
                // fix this somehow..
                message:
                  "An error occurred at the following entry: " +
                  error?.response?.data?.entry,
                disappearAfter: 3000,
                closeButtonExist: true,
              },
              { root: true },
            );
          } else {
            return await dispatch(
              "handleError",
              {
                error: error,
                callbackAction: "orders/placeOrder",
                callbackActionArgs: payload,
              },
              { root: true },
            );
          }
          return false;
        });
    },
    updateArchived({ dispatch, rootGetters }, payload: any) {
      return axios
        .put(API_URL + `order/${payload.orderToken}`, payload.payload, {
          headers: {
            ...rootGetters["auth/authHeader"],
          },
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/updateArchived",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
    updateOrder({ dispatch, rootGetters }, payload: any) {
      return axios
        .put(API_URL + `order/${payload.orderToken}`, payload.formData, {
          headers: {
            ...rootGetters["auth/authHeader"],
            ...{ "Content-Type": "multipart/form-data" },
          },
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/updateOrder",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
    updateContract({ dispatch, rootGetters }, payload: any) {
      return axios
        .put(
          API_URL + `order/${payload.orderToken}/contract`,
          payload.formData,
          {
            headers: {
              ...rootGetters["auth/authHeader"],
              ...{ "Content-Type": "multipart/form-data" },
            },
          },
        )
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/updateContract",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
    deleteContractFile({ dispatch, rootGetters }, payload: any) {
      return axios
        .delete(API_URL + `order/${payload.orderToken}/contract`, {
          headers: {
            ...rootGetters["auth/authHeader"],
          },
          data: { file_path: payload.file_path, type: payload.type },
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/deleteContractFile",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
    sendMessage({ dispatch, rootGetters }, payload: any) {
      return axios
        .post(API_URL + `order/chat/${payload.roomId}`, payload.formData, {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/sendMessage",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
    fetchMessages({ dispatch, rootGetters }, roomId: any) {
      return axios
        .get(API_URL + `order/chat/${roomId}`, {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/fetchMessages",
              callbackActionArgs: roomId,
            },
            { root: true },
          );
        });
    },
    deleteMessages({ commit, dispatch, rootGetters }, payload: any) {
      return axios
        .delete(API_URL + `order/chat/${payload.roomId}`, {
          headers: rootGetters["auth/authHeader"],
          data: { messageIds: payload.messageIds },
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/deleteMessages",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
    verifyPaymentSuccess({ dispatch, rootGetters }, payload: any) {
      return axios
        .get(API_URL + `order/${payload.orderToken}/verify-payment`, {
          headers: rootGetters["auth/authHeader"],
          params: { session_id: payload.session_id },
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/verifyPaymentSuccess",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
    updateOrderNotification({ dispatch, rootGetters }, payload: any) {
      return axios
        .put(
          API_URL + `order/${payload.orderToken}/notification`,
          payload.formData,
          {
            headers: rootGetters["auth/authHeader"],
          },
        )
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/updateOrderNotification",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
    searchTags({ dispatch, rootGetters }, query: string) {
      return axios
        .get(API_URL + `search_tags?q=${query}`, {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "orders/searchTags",
              callbackActionArgs: query,
            },
            { root: true },
          );
        });
    },
  },
  getters: {},
};

export default orders;
