<template>
  <div class="flex flex-col space-y-3 items-center justify-center w-full">
    <div
      :id="`drop-box-${name}`"
      @drop.prevent="onDropped"
      @dragover.prevent="onDragover"
      @dragleave="onDragleave"
      :class="`
    border-2
    border-gray-300
    border-dashed
    rounded-md
    p-${paddingSize}
    flex
    justify-center
    w-full
    `"
    >
      <div class="space-y-1 text-center">
        <svg
          class="mx-auto h-10 w-10 text-gray-400"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 48 48"
          aria-hidden="true"
        >
          <path
            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="flex text-sm sm:text-base text-gray-600">
          <label
            :for="`file-upload-${name}`"
            class="relative cursor-pointer rounded-md font-medium text-rooom-light hover:text-rooom-dark focus-within:outline-none"
          >
            <span>{{ $t("upload-files") }}</span>
            <input
              :id="`file-upload-${name}`"
              :name="`file-upload-${name}`"
              type="file"
              class="sr-only"
              @change="onFileChange"
              :multiple="fileCount > 1"
            />
          </label>
          <p class="pl-1">{{ $t("or-drag-and-drop") }}</p>
        </div>
        <p class="text-xs text-gray-500">
          {{ $t("up-to") }} {{ maximumFileMb }} MB
        </p>
      </div>
    </div>
    <!-- Files -->
    <div
      class="w-full max-h-44 flex flex-col space-y-2 items-center justify-start overflow-y-auto"
    >
      <div
        v-for="(file, index) in files"
        :key="index"
        class="grid grid-cols-12 gap-2 w-full items-center justify-between"
      >
        <div
          class="flex flex-row w-full col-span-11 space-x-2 items-center justify-start"
        >
          <DocumentIcon class="h-8 w-8 text-rooom-default shrink-0" />
          <div
            class="truncate flex flex-col items-start justify-start font-mono tracking-tight text-sm w-full leading-5"
          >
            <p class="truncate text-gray-700 font-semibold">
              {{ file.name }}
            </p>
            <p class="text-gray-500 font-normal">{{ file.size }} bytes</p>
          </div>
        </div>
        <XCircleIcon
          @click.prevent="removeFile(index)"
          class="h-7 w-7 col-span-1 justify-self-end cursor-pointer text-red-600 hover:text-red-700"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, PropType } from "vue";
import { DocumentIcon, XCircleIcon } from "@heroicons/vue/20/solid";

export default defineComponent({
  name: "FileUpload",
  components: {
    DocumentIcon,
    XCircleIcon,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    initialFiles: {
      type: Array as PropType<Array<File>>,
      default: () => [],
    },
    paddingSize: {
      type: String,
      default: "5",
    },
    fileCount: {
      type: Number,
      default: 50,
    },
    maximumFileMb: {
      type: Number,
      default: 50,
    },
  },
  emits: ["filesChanged"],
  setup(props, { emit }) {
    const files = ref<File[]>(props.initialFiles);

    watch(
      () => [...files.value],
      (newFiles) => {
        emit("filesChanged", newFiles);
      },
    );

    function addFiles(theFiles: FileList) {
      Array.from(theFiles).forEach((file) => {
        files.value.push(file);
      });
      if (props.fileCount === 1) {
        files.value = [files.value[files.value.length - 1]];
      } else if (files.value.length > props.fileCount) {
        files.value.splice(
          props.fileCount,
          files.value.length - props.fileCount,
        );
      }
    }

    function onDropped(e: DragEvent) {
      const droppedFiles = e.dataTransfer?.files;
      if (droppedFiles) addFiles(droppedFiles);
      document
        .querySelector(`#drop-box-${props.name}`)
        ?.classList.remove("dragged-over");
    }

    function onDragover() {
      document
        .querySelector(`#drop-box-${props.name}`)
        ?.classList.add("dragged-over");
    }

    function onDragleave() {
      document
        .querySelector(`#drop-box-${props.name}`)
        ?.classList.remove("dragged-over");
    }

    function onFileChange(e: Event) {
      const addedFile = (<HTMLInputElement>e.target).files;
      if (addedFile) addFiles(addedFile);
    }

    function removeFile(index: number) {
      files.value.splice(index, 1);
    }

    return {
      files,
      onDropped,
      onDragover,
      onDragleave,
      onFileChange,
      removeFile,
    };
  },
});
</script>

<style lang="scss" scoped>
.dragged-over {
  @apply bg-gray-300 bg-opacity-50;
}
</style>
