<template>
  <div class="fixed z-20 inset-0 overflow-y-auto">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
      <div
        class="inline-block align-bottom bg-white px-4 pt-5 pb-4 sm:p-8 rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-1/3"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <h3 class="text-lg leading-6 font-medium text-gray-900 mb-6">
          {{ $t("select-groups") }}
        </h3>
        <form
          enctype="multipart/form-data"
          class="space-y-6"
          @submit.prevent="onSubmit"
        >
          <div>
            <div class="flex flex-row justify-between items-end">
              <label
                for="items"
                class="sr-only block text-sm font-medium text-gray-700"
              >
                {{ $t("groups") }}
              </label>
            </div>
            <div class="mt-1 relative">
              <div class="relative flex items-stretch grow focus-within:z-20">
                <div
                  class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                >
                  <MagnifyingGlassIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="items"
                  id="search-item"
                  v-model="query"
                  @focus="searchFocused = true"
                  @blur="onInputBlur"
                  class="focus:ring-rooom-light focus:border-rooom-light focus:outline-none block w-full rounded-md pl-10 sm:text-sm border-gray-300 appearance-none placeholder-gray-400 px-3 py-2 shadow-sm"
                  :placeholder="$t('search-for-groups')"
                />
              </div>
              <!-- search dropbox -->
              <transition
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <ul
                  v-show="searchFocused"
                  class="absolute z-30 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                >
                  <!--
        Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

        Highlighted: "text-white bg-indigo-600", Not Highlighted: "text-gray-900"
      -->
                  <li
                    @click.prevent="select(item)"
                    v-for="(item, itemIdx) in groups"
                    :key="itemIdx"
                    class="cursor-pointer text-gray-900 select-none relative py-2 pl-3 pr-9 hover:bg-rooom-lightest"
                    id="listbox-option-0"
                    role="option"
                  >
                    <div class="flex flex-row">
                      <span
                        class="px-2 inline-flex items-center text-xs leading-5 font-semibold rounded-full"
                      >
                        <svg
                          class="-ml-0.5 mr-1.5 h-6 w-6"
                          :style="{
                            color: item.color,
                          }"
                          fill="currentColor"
                          viewBox="0 0 8 8"
                        >
                          <circle cx="4" cy="4" r="3" />
                        </svg>
                      </span>
                      <span class="text-sm font-medium text-gray-900 truncate">
                        {{ item.name }}
                      </span>
                    </div>
                    <span
                      v-if="
                        selected.find(
                          (selectedItem) => selectedItem.name === item.name,
                        )
                      "
                      class="text-rooom-light absolute inset-y-0 right-0 flex items-center pr-4"
                    >
                      <!-- Heroicon name: solid/check -->
                      <svg
                        class="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </li>

                  <!-- More items... -->
                </ul>
              </transition>
              <ul
                class="mt-2 border-gray-200 divide-y divide-gray-200 overflow-y-auto max-h-52 px-2"
              >
                <li
                  v-for="(item, itemIdx) in selected"
                  :key="itemIdx"
                  class="text-gray-900 select-none relative py-2"
                >
                  <div class="flex flex-row items-center justify-between">
                    <div class="flex flex-row items-center space-x-2">
                      <svg
                        class="h-6 w-6"
                        :style="{ color: item.color }"
                        fill="currentColor"
                        viewBox="0 0 8 8"
                      >
                        <circle cx="4" cy="4" r="3" />
                      </svg>
                      <span class="text-sm font-medium truncate">
                        {{ item.name }}
                      </span>
                    </div>
                    <button
                      @click.prevent="removeSelectedItem(itemIdx)"
                      type="button"
                      class="bg-white rounded-md text-sm font-medium text-red-600 hover:text-red-500 focus:outline-none"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="flex flex-row space-x-5">
            <button
              :disabled="selected.length === 0"
              type="submit"
              class="disabled:cursor-not-allowed disabled:opacity-50 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-rooom-default hover:bg-rooom-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rooom-light"
            >
              {{ $t("assign") }}
            </button>
            <button
              @click.prevent="close"
              class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              {{ $t("cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed } from "vue";
import { useStore } from "vuex";
import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline";

export default defineComponent({
  name: "GroupsSelectionPanel",
  components: {
    MagnifyingGlassIcon,
  },
  setup() {
    const store = useStore();
    const groups = computed(() => {
      return store.state.groups.groups;
    });
    const selected = ref(<any>[]);
    const searchFocused = ref(false);
    const query = ref("");

    watch(query, () => {
      getItems();
    });

    async function getItems() {
      const params = {
        query: query.value,
        sort: "modified",
      };
      store.dispatch("groups/getGroups", params);
    }

    function select(item: any) {
      const idxInSelected = selected.value.findIndex(
        (selectedItem: any) => selectedItem.name === item.name,
      );
      if (idxInSelected === -1) {
        selected.value.push(item);
      } else {
        removeSelectedItem(idxInSelected);
      }
    }

    function removeSelectedItem(itemIdx: number) {
      selected.value.splice(itemIdx, 1);
    }

    function onSubmit() {
      const result = selected.value.map((item: any) => item.name);
      store.dispatch("submitOnPanel", {
        panelType: "selectGroups",
        result: result,
      });
    }

    getItems();

    function close() {
      store.dispatch("closePanel", "selectGroups");
      // reset
      query.value = "";
      selected.value = [];
    }

    function onInputBlur() {
      setTimeout(() => (searchFocused.value = false), 300);
    }

    return {
      query,
      groups,
      onSubmit,
      searchFocused,
      select,
      close,
      selected,
      removeSelectedItem,
      onInputBlur,
    };
  },
});
</script>
