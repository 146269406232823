<template>
  <div class="fixed z-20 inset-0 overflow-y-auto">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
      <div
        class="inline-block align-bottom bg-white px-4 pt-5 pb-4 sm:p-8 rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-96"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="mb-7 flex flex-col space-y-5">
          <p class="text-lg leading-6 font-medium text-gray-900">
            {{ message }}
          </p>
          <div
            v-if="
              inputType === 'email' ||
              inputType === 'firstName' ||
              inputType === 'lastName' ||
              inputType === 'description' ||
              inputType === 'Group Name' ||
              inputType === 'position' ||
              inputType === 'phone' ||
              inputType === 'phone2'
            "
            class="w-full sm:max-w-xs"
          >
            <input
              type="text"
              v-model="panelInput"
              name="panelInput"
              id="panelInput"
              class="shadow-sm focus:ring-rooom-light focus:border-rooom-light block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div
            v-if="inputType.includes('Description')"
            class="w-full sm:max-w-xs"
          >
            <textarea
              :rows="4"
              placeholder=""
              class="text-box"
              v-model="panelInput"
            />
          </div>
          <div v-else-if="inputType === 'role'" class="w-full sm:max-w-xs">
            <div class="mt-1 relative">
              <select
                v-model="panelInput"
                id="panelSelect"
                name="panelSelect"
                class="appearance-none block w-full px-3 py-2 text-base border-gray-300 focus:outline-none focus:ring-rooom-light focus:border-rooom-light sm:text-sm rounded-md"
              >
                <option value="admin" class="py-2">
                  {{ $t("admin") }}
                </option>
                <option value="partner">
                  {{ $t("partner") }}
                </option>
                <option value="manager">
                  {{ $t("manager") }}
                </option>
                <option value="customer">
                  {{ $t("customer") }}
                </option>
              </select>
            </div>
          </div>
          <div v-else-if="inputType === 'image'" class="w-full sm:max-w-xs">
            <file-upload
              name="profile-image-update"
              @filesChanged="onFileChanged"
              :fileCount="1"
              paddingSize="5"
            />
          </div>
          <div
            v-else-if="inputType === 'Group Color'"
            class="w-full sm:max-w-xs"
          >
            <div class="mt-1 relative flex justify-center items-center">
              <div
                class="w-32 flex flex-row justify-start items-center space-x-2"
              >
                <input
                  type="color"
                  id="panelColor"
                  name="panelColor"
                  v-model="panelInput"
                />
                <span
                  class="text-sm font-medium text-gray-600 tracking-wider"
                  >{{ panelInput }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-row space-x-5">
          <button
            @click.prevent="yes"
            class="disabled:cursor-not-allowed disabled:opacity-50 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-rooom-default hover:bg-rooom-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rooom-light"
          >
            {{ yesMessage }}
          </button>
          <button
            @click.prevent="no"
            class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          >
            {{ noMessage }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
import FileUpload from "@/components/FileUpload.vue";

export default defineComponent({
  name: "ThePanel",
  components: {
    FileUpload,
  },
  props: {
    message: String,
    yesMessage: String,
    noMessage: String,
    input: null,
    inputType: String,
  },
  setup(props) {
    const store = useStore();
    const panelInput = ref(props.input);

    watch(panelInput, (newValue) => {
      store.dispatch("setPanelInput", newValue);
    });

    function onFileChanged(theFiles: File[], field: string) {
      if (field) {
        panelInput.value[field] = theFiles;
      } else {
        panelInput.value = theFiles[0];
      }
    }

    function no() {
      store.commit("noClickedOnPanel");
    }

    function yes() {
      store.commit("yesClickedOnPanel");
    }

    function handleRadioInputChange(newInput: string) {
      panelInput.value = newInput;
    }

    return {
      no,
      yes,
      panelInput,
      onFileChanged,
      handleRadioInputChange,
    };
  },
});
</script>

<style lang="scss" scoped>
#panelColor {
  @apply border-2 border-dashed border-gray-300 p-0.5 rounded-full w-9 h-9 outline-none cursor-pointer;
  -webkit-appearance: none;
}

#panelColor::-webkit-color-swatch-wrapper {
  padding: 0;
}
#panelColor::-webkit-color-swatch {
  border: none;
  border-radius: 100%;
}
.text-box {
  @apply w-full
            shadow-sm
            block
            focus:ring-rooom-light
            focus:border-rooom-light
            sm:text-sm
            border-gray-300
            rounded-md;
}
</style>
