<template>
  <div class="fixed z-20 inset-0 overflow-y-auto">
    <div
      class="flex items-end justify-center min-h-screen p-0 text-center sm:block"
    >
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
      <div
        class="inline-block align-bottom bg-white px-4 pt-2 sm:p-5 rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-screen-md sm:w-1/2 h-screen w-screen sm:h-5/6"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="flex flex-col h-full">
          <div class="flex flex-row justify-between items-center px-1.5 py-1">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{ $t("new-changelog") }}
            </h3>
            <div class="flex flex-row justify-center items-center space-x-4">
              <button
                class="disabled:text-slate-300 text-green-400 hover:text-green-500 focus:outlinee-none"
                :disabled="!version || !content"
                @click.prevent="addNewChangelog"
              >
                <CheckIcon class="h-5 w-5" />
              </button>
              <XMarkIcon
                class="h-5 w-5 text-slate-400 hover:text-slate-500 cursor-pointer"
                @click.prevent="$emit('close')"
              />
            </div>
          </div>
          <div class="mt-3 overflow-y-auto py-1 px-2">
            <div>
              <label
                for="version"
                class="block text-sm font-medium text-gray-700"
                >Version</label
              >
              <div class="mt-2">
                <input
                  type="text"
                  name="version"
                  v-model="version"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-rooom-light focus:ring-rooom-light sm:text-sm"
                  placeholder="1.0.0"
                />
              </div>
            </div>
            <div class="mt-5">
              <div class="flex flex-row justify-between items-center">
                <label
                  for="content"
                  class="block text-sm font-medium text-gray-700"
                >
                  Contents</label
                >
                <div class="flex items-center">
                  <button
                    type="button"
                    :class="[
                      previewEnabled ? 'bg-rooom-default' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
                    ]"
                    role="switch"
                    :aria-checked="previewEnabled"
                    @click.prevent="previewEnabled = !previewEnabled"
                  >
                    <span
                      :class="[
                        previewEnabled ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                      ]"
                    >
                      <span
                        :class="[
                          previewEnabled
                            ? 'opacity-0 east-out duration-100'
                            : 'opacity-100 ease-in duration-200',
                          'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                        ]"
                        aria-hidden="true"
                      >
                        <EyeSlashIcon class="h-3 w-3 text-gray-400" />
                      </span>
                      <span
                        :class="[
                          previewEnabled
                            ? 'opacity-100 ease-in duration-200'
                            : 'opacity-0 ease-out duration-100',
                          'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                        ]"
                        aria-hidden="true"
                      >
                        <EyeIcon class="h-3 w-3 text-rooom-default" />
                      </span>
                    </span>
                  </button>
                  <span class="ml-2">
                    <span class="text-sm font-medium text-gray-500">{{
                      $t("preview")
                    }}</span>
                  </span>
                </div>
              </div>
              <div v-if="!previewEnabled" class="mt-2">
                <textarea
                  @drop.prevent="onDropped"
                  rows="7"
                  v-model="content"
                  name="content"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-rooom-light focus:ring-rooom-light sm:text-sm"
                  placeholder="### Added
- New feature...

### Fixed
- Fixed bug..."
                ></textarea>
                <p
                  class="text-xs mt-1.5 ml-0.5 inline-flex items-center text-slate-600"
                >
                  <PhotoIcon class="h-4 w-4 mr-1.5 inline" /><span
                    >To insert images, just drag and drop the file.</span
                  >
                </p>
              </div>
              <div v-else-if="content" class="mt-2">
                <Markdown
                  class="prose prose-sm prose-slate w-full rounded-md py-2 px-4 bg-slate-100"
                  :source="content"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <LoadingSpinner class="z-40" v-if="loadingSpinnerOpen" message="Loading..." />
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import {
  XMarkIcon,
  EyeIcon,
  EyeSlashIcon,
  PhotoIcon,
} from "@heroicons/vue/20/solid";
import { CheckIcon } from "@heroicons/vue/24/outline";
import Markdown from "vue3-markdown-it";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default defineComponent({
  name: "NewChangelogPanel",
  components: {
    XMarkIcon,
    EyeIcon,
    EyeSlashIcon,
    CheckIcon,
    Markdown,
    PhotoIcon,
    LoadingSpinner,
  },
  emits: ["close", "added"],
  setup(_, { emit }) {
    const store = useStore();

    const version = ref("");
    const content = ref("");

    const previewEnabled = ref(false);

    function addNewChangelog() {
      const unusedImageUrls: string[] = [];
      imageUrls.forEach((url: string) => {
        if (content.value.search(url) === -1) {
          unusedImageUrls.push(url);
        }
      });
      const payload = {
        version: version.value,
        content: content.value,
        unused_image_urls: unusedImageUrls,
      };
      store.dispatch("auth/addChangelog", payload).then(() => {
        emit("added");
      });
    }

    function addFiles(theFiles: FileList) {
      // TODO: add file size and count and type check
      const payload = new FormData();
      Array.from(theFiles).forEach((file) => {
        payload.append("images", file);
      });
      store
        .dispatch("auth/addChangelogImages", payload)
        .then((response: any) => {
          // TODO: handle the errors
          response.data.files.forEach((entry: any) => {
            const url = Object.values(entry)[0] as string;
            content.value += `![${Object.keys(entry)[0]}](${url})
`;
            imageUrls.push(url);
          });
          loadingSpinnerOpen.value = false;
        });
      // TODO: remove this when the backend functions are added (or better, handle the error..)
      loadingSpinnerOpen.value = false;
    }

    function onDropped(e: DragEvent) {
      loadingSpinnerOpen.value = true;
      const droppedFiles = e.dataTransfer?.files;
      if (droppedFiles) addFiles(droppedFiles);
    }

    const loadingSpinnerOpen = ref(false);
    const imageUrls: string[] = [];

    return {
      version,
      content,
      addNewChangelog,
      previewEnabled,
      onDropped,
      loadingSpinnerOpen,
    };
  },
});
</script>
