import { Module } from "vuex";
import { RootState } from "@/store/index";
import { User } from "@/types/auth.interface";
import axios from "axios";
import router from "@/router";
import { selectedLocale } from "@/i18n";
import { UserProfileUpdate } from "@/types/accounts.interface";

const user = JSON.parse(localStorage.getItem("user") || "{}");
const initialState =
  Object.keys(user).length !== 0
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null };
const API_URL = process.env.VUE_APP_BACKEND_URL + "/auth/";

interface auth {
  status: {
    loggedIn: boolean;
  };
  user: User | null;
  profile: any;
  updatePanel: {
    open: boolean;
    message: string;
    yesMessage: string;
    prefilledValue: string;
    updateField: string;
    maxLength: number;
    userId: string | null;
  };
  currentVersion: string;
}

const auth: Module<auth, RootState> = {
  namespaced: true,
  state: {
    ...initialState,
    ...{
      profile: {},
      updatePanel: {
        open: false,
        message: "",
        yesMessage: "Update",
        prefilledValue: "",
        updateField: "",
        maxLength: 0,
        userId: null,
      },
      currentVersion:
        localStorage.getItem("cm_version") !== null
          ? (localStorage.getItem("cm_version") as string)
          : "",
    },
  },
  mutations: {
    loginSuccess(state, user: User) {
      state.user = user;
      state.status.loggedIn = true;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    refreshSuccess(state) {
      state.user = JSON.parse(localStorage.getItem("user") || "{}");
    },
    refreshAccessToken(state, accessToken: string) {
      state.user!.accessToken = accessToken;
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    openProfileUpdatePanel(state, payload: auth["updatePanel"]) {
      state.updatePanel.updateField = payload.updateField;
      state.updatePanel.message = payload.message;
      state.updatePanel.yesMessage = payload.yesMessage;
      state.updatePanel.prefilledValue = payload.prefilledValue;
      state.updatePanel.maxLength = payload.maxLength;
      state.updatePanel.open = true;
      if (payload.userId) {
        state.updatePanel.userId = payload.userId;
      } else {
        state.updatePanel.userId = null;
      }
    },
    setProfileUpdatePanelField(state, field: string) {
      state.updatePanel.updateField = field;
    },
    closeProfileUpdatePanel(state) {
      state.updatePanel.open = false;
    },
    setProfile(state, profile: any) {
      state.profile = profile;
    },
    setCurrentVersion(state, version: string) {
      state.currentVersion = version;
      localStorage.setItem("cm_version", version);
    },
  },
  actions: {
    login({ commit, dispatch }, payload: any) {
      return axios
        .post(API_URL + "login", payload)
        .then((response) => {
          if (response.data.accessToken) {
            const loginData = response.data;
            commit("setProfile", response.data);
            localStorage.setItem("user", JSON.stringify(loginData));
            commit("loginSuccess", loginData);
            if (!loginData.language_setting) {
              const formData = new FormData();
              formData.append("field", "language_setting");
              formData.append("value", selectedLocale);
              dispatch(
                "auth/updateUserProfile",
                {
                  formData: formData,
                  userId: loginData.id,
                },
                { root: true },
              );
            } else {
              commit("setCurrentLanguage", loginData.language_setting, {
                root: true,
              });
            }
            if (["pending", "approving"].includes(loginData.status)) {
              commit(
                "openBanner",
                {
                  type: loginData.status === "pending" ? "error" : "warning",
                  mainText: `${loginData.status}-banner-message-main`,
                  shortText: `${loginData.status}-banner-message-short`,
                  linkText:
                    loginData.status === "pending" ? "go-to-settings" : "",
                  link: loginData.status === "pending" ? "/user/settings" : "",
                },
                { root: true },
              );
              localStorage.setItem("bannerClosed", "false");
            }
            if (loginData.current_version) {
              commit("setCurrentVersion", loginData.current_version);
            }
          } else {
            commit(
              "openAlert",
              {
                type: "error",
                message: "Login failed: Access token not received",
              },
              { root: true },
            );
            commit("loginFailure");
          }
        })
        .catch((error) => {
          commit(
            "openAlert",
            {
              type: "error",
              message: error.response.status
                ? `login-post-${error.response.status}`
                : "error-message",
              disappearAfter: 3000,
              closeButtonExist: true,
            },
            { root: true },
          );
          commit("loginFailure");
        });
    },
    fetchProfileWithId({ dispatch, getters }, userId: string) {
      return axios
        .get(API_URL + `profile/${userId}`, {
          headers: getters.authHeader,
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "auth/fetchProfileWithId",
              callbackActionArgs: userId,
            },
            { root: true },
          );
        });
    },
    fetchProfile({ dispatch, getters, commit }, userId: string) {
      return axios
        .get(API_URL + `profile/${userId}`, {
          headers: getters.authHeader,
        })
        .then((response) => {
          commit("setProfile", response.data);
          const status = response.data.status;
          if (
            getters["user"].role !== "admin" &&
            ["pending", "approving"].includes(status)
          ) {
            localStorage.setItem("bannerClosed", "false");
            commit(
              "openBanner",
              {
                type: status === "pending" ? "error" : "warning",
                mainText: `${status}-banner-message-main`,
                shortText: `${status}-banner-message-short`,
                linkText: status === "pending" ? "go-to-settings" : "",
                link: status === "pending" ? "/user/settings" : "",
              },
              { root: true },
            );
          } else {
            commit("closeBanner", false, { root: true });
          }
          return response.data;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "auth/fetchProfile",
              callbackActionArgs: userId,
            },
            { root: true },
          );
        });
    },
    updateUserProfile({ state, commit, dispatch, getters }, payload: any) {
      return axios
        .put(API_URL + `profile/${payload.userId}`, payload.formData, {
          headers: {
            ...getters.authHeader,
            ...{ "Content-Type": "multipart/form-data" },
          },
        })
        .then((response) => {
          if (payload.userId === state.user?.id) {
            dispatch("fetchProfile", state.user?.id);
          } else {
            commit("accounts/setUpdated", true, { root: true });
          }
          if (state.updatePanel.updateField === "image") {
            commit("setProfileUpdatePanelField", "");
          }
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "auth/updateUserProfile",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
    logout({ commit, getters }) {
      return axios
        .delete(API_URL + "logout", { headers: getters.authHeader })
        .then(() => {
          localStorage.removeItem("user");
          commit("orders/setOrders", [], { root: true });
          commit("accounts/setAccounts", [], { root: true });
          commit("groups/setGroups", [], { root: true });
          commit("logout");
          router.push("/login");
        })
        .catch((error) => {
          localStorage.removeItem("user");
          commit("logout");
          router.push("/login");
        });
    },
    register({ commit }, payload: FormData) {
      return axios
        .post(API_URL + "register", payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          commit(
            "openAlert",
            {
              type: "error",
              message: error.response.status
                ? `register-post-${error.response.status}`
                : "error-message",
              disappearAfter: 3000,
              closeButtonExist: true,
            },
            { root: true },
          );
          return false;
        });
    },
    refresh({ commit, getters, state, dispatch }) {
      if (state.user && state.user.refreshToken) {
        return axios
          .post(
            API_URL + "refresh",
            {},
            {
              headers: getters.authHeaderWithRefreshToken,
            },
          )
          .then((response) => {
            if (response.data.accessToken) {
              localStorage.setItem("user", JSON.stringify(response.data));
              if (state.user) {
                commit("refreshAccessToken", state.user.accessToken);
                state.user.accessToken = response.data.accessToken;
                return true;
              }
            } else {
              dispatch("logout");
              return false;
            }
          })
          .catch((error) => {
            dispatch("logout");
            return false;
          });
      } else {
        dispatch("logout");
      }
    },
    accessConfirmRegistrationPage(_, token: string) {
      return axios
        .put(
          API_URL + "register",
          {},
          {
            headers: { token: token },
          },
        )
        .then((response) => {
          return response;
        });
    },
    forgotPassword(_, email) {
      return axios
        .post(API_URL + "forgot-password", {
          email: email,
        })
        .then(
          (response) => {
            return response.data;
          },
          (error) => {
            throw new Error(error.response.data);
          },
        );
    },
    registerForgotPassword(_, email: string) {
      return axios
        .post(API_URL + "forgot-password", {
          email: email,
        })
        .then(
          (response) => {
            return response.data;
          },
          (error) => {
            throw new Error(error.response.data);
          },
        );
    },
    accessResetPasswordPage(_, token: string) {
      return axios
        .get(API_URL + "reset-password", {
          headers: { token: token },
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            throw new Error("reset-password-unauthorized-access");
          } else if (error.response.status === 409) {
            throw new Error("reset-password-already-reset");
          } else if (error.response.status === 410) {
            throw new Error("reset-password-link-expired");
          }
        });
    },
    resetPassword({ commit }, payload: { password: string; token: string }) {
      return axios
        .put(
          API_URL + "reset-password",
          { password: payload.password },
          {
            headers: { token: payload.token },
          },
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          commit(
            "openAlert",
            {
              type: "error",
              message: error.response?.data.message
                ? error.response.data.message
                : error.response?.data
                  ? error.response.data
                  : error.message
                    ? error.message
                    : "An error occurred",
            },
            { root: true },
          );
        });
    },
    getPaymentMethod({ dispatch, getters }, userId: string) {
      return axios
        .get(API_URL + `payment-method/${userId}`, {
          headers: getters.authHeader,
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            { error: error, callbackAction: "auth/getPaymentMethod" },
            { root: true },
          );
        });
    },
    getStripeApiKey({ dispatch, getters }, payload: any) {
      return axios
        .get(API_URL + `stripe/${payload.userId}`, {
          headers: payload.accessToken
            ? payload.accessToken
            : getters.authHeader,
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "auth/getStripeApiKey",
              callbackActionArgs: payload.accessToken,
            },
            { root: true },
          );
        });
    },
    createCheckoutSession({ dispatch, getters }, userId?: string) {
      return axios
        .post(
          API_URL + `payment/checkout${userId ? "/" + userId : ""}`,
          {},
          {
            headers: getters.authHeader,
          },
        )
        .then((response) => {
          return response;
        })
        .catch((error) => {
          dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "auth/createCheckoutSession",
            },
            { root: true },
          );
        });
    },
    getChangelogs({ state, dispatch, rootGetters }) {
      return axios
        .get(API_URL + "changelogs", {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "auth/getChangelogs",
            },
            { root: true },
          );
        });
    },
    addChangelogImages({ state, dispatch, rootGetters }, payload: any) {
      return axios
        .post(API_URL + "changelogs/images", payload, {
          headers: rootGetters["auth/authHeader"],
          ...{ "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "auth/addChangelogImages",
            },
            { root: true },
          );
        });
    },
    addChangelog({ state, dispatch, rootGetters }, payload: any) {
      return axios
        .post(API_URL + "changelogs", payload, {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "auth/addChangelog",
            },
            { root: true },
          );
        });
    },
    editChangelog({ state, dispatch, rootGetters }, payload: any) {
      return axios
        .put(API_URL + "changelogs", payload, {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "auth/editChangelog",
            },
            { root: true },
          );
        });
    },
    deleteChangelog({ state, dispatch, rootGetters }, id: number | string) {
      return axios
        .delete(API_URL + "changelogs", {
          headers: rootGetters["auth/authHeader"],
          data: { id: id },
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "auth/deleteChangelog",
            },
            { root: true },
          );
        });
    },
  },
  getters: {
    getJWT(state) {
      return state.user?.accessToken;
    },
    authHeader(state) {
      if (state.user && state.user.accessToken) {
        return { Authorization: "Bearer " + state.user.accessToken };
      } else {
        return {};
      }
    },
    authHeaderWithRefreshToken(state) {
      if (state.user && state.user.refreshToken) {
        return { Authorization: "Bearer " + state.user.refreshToken };
      } else {
        return {};
      }
    },
    loggedIn(state) {
      return state.status.loggedIn;
    },
    user(state) {
      return state.user;
    },
  },
};

export default auth;
