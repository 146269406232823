<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
      <div
        class="inline-block align-bottom bg-white px-4 pt-5 pb-4 sm:p-8 rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-96"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="mb-7 flex flex-col space-y-5">
          <p class="text-lg leading-6 font-medium text-gray-900">
            {{ $t(message) }}
          </p>
          <div v-if="field === 'description'" class="w-full sm:max-w-xs">
            <textarea
              :rows="4"
              placeholder=""
              class="text-box"
              v-model="input"
              :maxlength="maxLength"
            />
          </div>
          <div
            v-else-if="field === 'role' || field === 'country'"
            class="w-full sm:max-w-xs"
          >
            <div class="mt-1 relative">
              <button
                type="button"
                @click.prevent="selectOptionsOpen = !selectOptionsOpen"
                class="cursor-pointer bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-rooom-light focus:border-rooom-light sm:text-sm"
                aria-haspopup="listbox"
                aria-expanded="true"
                aria-labelledby="listbox-label"
              >
                <span
                  v-if="field === 'country'"
                  class="block truncate"
                  :class="[input ? 'text-gray-700' : 'textpgray-500']"
                >
                  {{
                    getCountryName(input)
                      ? getCountryName(input)
                      : getCountryName(prefilledValue)
                        ? getCountryName(prefilledValue)
                        : $t("choose-a-country")
                  }}
                </span>
                <span
                  v-else-if="field === 'role'"
                  class="block truncate"
                  :class="[input.value ? 'text-gray-700' : 'textpgray-500']"
                >
                  {{ $t(input) }}
                </span>
                <span
                  class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                >
                  <ChevronUpDownIcon class="h-5 w-5 text-gray-400" />
                </span>
              </button>
              <ul
                v-if="selectOptionsOpen"
                class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                tabindex="-1"
                role="listbox"
                aria-labelledby="listbox-label"
                aria-activedescendant="listbox-option-3"
              >
                <li
                  @click.prevent="
                    input = option[0];
                    selectOptionsOpen = false;
                  "
                  v-for="option in options"
                  :key="option[0]"
                  class="cursor-pointer hover:text-white hover:bg-rooom-primary text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9"
                  role="option"
                >
                  <span
                    v-if="field === 'country'"
                    class="font-normal block truncate"
                  >
                    {{ option[1] }}
                  </span>
                  <span
                    v-else-if="field === 'role'"
                    class="font-normal block truncate"
                  >
                    {{ $t(option[0]) }}
                  </span>

                  <!--
          Checkmark, only display for selected option.

          Highlighted: "text-white", Not Highlighted: "text-rooom-primary"
        -->
                  <span
                    v-if="input === option[0]"
                    class="text-rooom-primary absolute inset-y-0 right-0 flex items-center pr-4"
                  >
                    <CheckIcon class="h-5 w-5" />
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div v-else-if="field === 'image'" class="w-full sm:max-w-xs">
            <file-upload
              name="profile-image-update"
              @filesChanged="onFileChanged"
              :fileCount="1"
              paddingSize="5"
            />
          </div>
          <div v-else-if="field === 'tags'" class="w-full sm:max-w-xs">
            <input
              type="text"
              v-model="newTagInput"
              name="tagInput"
              id="tagInput"
              :maxlength="maxLength"
              class="shadow-sm focus:ring-rooom-light focus:border-rooom-light block w-full sm:text-sm border-gray-300 rounded-md"
            />
            <ul
              class="mt-3 flex flex-wrap space-x-1.5 max-w-full space-y-0.5 items-center"
            >
              <li v-for="(tag, index) in input" :key="index">
                <span
                  class="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-normal bg-rooom-lightest text-rooom-primary"
                >
                  {{ tag }}
                  <button
                    @click.prevent="removeTag(tag)"
                    type="button"
                    class="shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-rooom-light hover:bg-rooom-lighter hover:text-rooom-light focus:outline-none"
                  >
                    <svg
                      class="h-2 w-2"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 8 8"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-width="1.5"
                        d="M1 1l6 6m0-6L1 7"
                      />
                    </svg>
                  </button>
                </span>
              </li>
            </ul>
          </div>
          <div v-else-if="field === 'company_size'" class="w-full sm:max-w-xs">
            <input
              type="number"
              v-model="input"
              name="input"
              id="input"
              min="1"
              class="shadow-sm focus:ring-rooom-light focus:border-rooom-light block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div v-else-if="field === 'password'" class="w-full sm:max-w-xs">
            <label
              for="currentPassword"
              class="block text-sm font-medium text-gray-700"
              >{{ $t("current-password") }}</label
            >
            <div class="mt-1 relative rounded-md shadow-sm">
              <input
                v-model="currentPassword"
                type="password"
                name="currentPassword"
                class="block w-full pr-10 sm:text-sm rounded-md focus:outline-none border-rooom-lighter text-rooom-dark focus:ring-rooom-light focus:border-rooom-light"
              />
            </div>
            <label
              for="newPassword"
              class="mt-3 block text-sm font-medium text-gray-700"
              >{{ $t("new-password") }}</label
            >
            <div class="mt-1 relative rounded-md shadow-sm">
              <input
                v-model="input"
                type="password"
                name="newPassword"
                class="block w-full pr-10 sm:text-sm rounded-md focus:outline-none border-rooom-lighter text-rooom-dark focus:ring-rooom-light focus:border-rooom-light"
              />
              <div
                v-if="passwordError"
                class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
              >
                <ExclamationCircleIcon class="h-5 w-5 text-red-500" />
              </div>
            </div>
            <p v-if="passwordError" class="mt-2 text-sm text-red-600">
              {{ $t("password-error") }}
            </p>
            <label
              for="passwordConfirm"
              class="mt-3 block text-sm font-medium text-gray-700"
              >{{ $t("confirm-new-password") }}</label
            >
            <div class="mt-1 relative rounded-md shadow-sm">
              <input
                v-model="passwordConfirm"
                type="password"
                name="passwordConfirm"
                class="shadow-sm focus:ring-rooom-light focus:border-rooom-light block w-full sm:text-sm border-gray-300 rounded-md"
              />
              <div
                v-if="passwordMatchError"
                class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
              >
                <ExclamationCircleIcon class="h-5 w-5 text-red-500" />
              </div>
            </div>
            <p v-if="passwordMatchError" class="mt-2 text-sm text-red-600">
              {{ $t("passwords-must-match") }}
            </p>
          </div>
          <div v-else class="w-full sm:max-w-xs">
            <input
              type="text"
              v-model="input"
              name="input"
              id="input"
              :maxlength="maxLength"
              class="shadow-sm focus:ring-rooom-light focus:border-rooom-light block w-full sm:text-sm border-gray-300 rounded-md"
            />
            <p
              v-if="!submitEnabled && input.value !== ''"
              class="pl-1 mt-2 text-sm text-red-600"
            >
              <ExclamationCircleIcon class="w-5 h-5 inline mr-2" />{{
                $t(errorMessage)
              }}
            </p>
          </div>
        </div>
        <div class="flex flex-row space-x-5">
          <button
            @click.prevent="onUpdate"
            :disabled="!submitEnabled"
            :class="[
              submitEnabled
                ? 'cursor-poitner bg-opacity-100 hover:bg-rooom-dark'
                : 'cursor-not-allowed bg-opacity-50',
              'w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-rooom-default focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rooom-light',
            ]"
          >
            {{ $t(yesMessage) }}
          </button>
          <button
            @click.prevent="onCancel"
            class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          >
            {{ $t("cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import FileUpload from "@/components/FileUpload.vue";
import {
  ExclamationCircleIcon,
  ChevronUpDownIcon,
  CheckIcon,
} from "@heroicons/vue/20/solid";
import { useI18n } from "vue-i18n";

const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/de.json"));

export default defineComponent({
  name: "ProfileUpdatePanel",
  components: {
    FileUpload,
    ExclamationCircleIcon,
    ChevronUpDownIcon,
    CheckIcon,
  },
  setup() {
    const store = useStore();
    const userId = computed(() => store.state.auth.updatePanel.userId);
    const field = computed(() => store.state.auth.updatePanel.updateField);
    const message = computed(() => store.state.auth.updatePanel.message);
    const yesMessage = computed(() => store.state.auth.updatePanel.yesMessage);
    const prefilledValue = computed(
      () => store.state.auth.updatePanel.prefilledValue,
    );
    const maxLength = computed(() => store.state.auth.updatePanel.maxLength);
    const input = ref<any>(prefilledValue.value);
    if (!input.value) {
      input.value = "";
    }
    const newTagInput = ref("");
    const errorMessage = ref("input-value-must-be-valid");
    const { locale } = useI18n();
    const selectOptionsOpen = ref(false);
    const tagsUpdated = ref(false);

    const roleOptions = [
      ["admin", "admin"],
      ["manager", "manager"],
      ["partner", "partner"],
      ["customer", "customer"],
    ];

    const options = computed(() => {
      if (field.value === "role") {
        return roleOptions;
      } else {
        return Object.entries(
          countries.getNames(locale.value, {
            select: "official",
          }),
        ).map((entry) => [entry[0], entry[1]]);
      }
    });
    function handleRadioInputChange(newInput: string) {
      input.value = newInput;
    }

    function onFileChanged(theFiles: File[]) {
      input.value = theFiles[theFiles.length - 1];
    }

    function onCancel() {
      store.commit("auth/closeProfileUpdatePanel");
    }
    const submitEnabled = computed(() => {
      let regExp = /test/i;
      if (field.value === "phone") {
        regExp =
          /^$|^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
      } else if (field.value === "website") {
        regExp =
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
      } else if (field.value === "iban") {
        regExp =
          // eslint-disable-next-line no-useless-escape
          /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/gm;
      } else if (field.value === "tags") {
        if (tagsUpdated.value) {
          return true;
        } else if (newTagInput.value === "" && !tagsUpdated.value) {
          return false;
        } else if (!input.value || input.value.length === 0) {
          return true;
        } else if (input.value.includes(newTagInput.value)) {
          return false;
        } else {
          return true;
        }
      } else if (field.value === "company_size") {
        if (input.value < 1) {
          return false;
        } else {
          return true;
        }
      } else if (field.value === "password") {
        if (
          passwordMatchError.value ||
          passwordError.value ||
          currentPassword.value === ""
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
      if (!input.value || input.value === "" || input.value.match(regExp)) {
        document
          .querySelector('input[name="input"]')
          ?.classList.remove("error");
        return true;
      } else {
        document.querySelector('input[name="input"]')?.classList.add("error");
        return false;
      }
    });

    function getCountryName(countryCode: string) {
      return countries.getName(countryCode, locale.value);
    }

    function onUpdate() {
      if (submitEnabled.value) {
        const formData = new FormData();
        formData.append("field", field.value);
        if (field.value === "tags") {
          if (!input.value) {
            input.value = [];
          }
          let updatedTags = [];
          updatedTags = input.value.slice();
          if (
            newTagInput.value !== "" &&
            !updatedTags.includes(newTagInput.value)
          ) {
            updatedTags.push(newTagInput.value);
          }
          updatedTags.forEach((tag: string) => {
            formData.append("values", tag);
          });
        } else {
          formData.append("value", input.value);
        }
        if (field.value === "password") {
          formData.append("currentPassword", currentPassword.value);
        }
        const payload = {
          formData: formData,
          userId: userId.value ? userId.value : store.state.auth.user.id,
        };
        store
          .dispatch("auth/updateUserProfile", payload)
          .then((response: any) => {
            store.commit("auth/closeProfileUpdatePanel");
          });
      }
    }

    function removeTag(tag: string) {
      input.value = [...input.value.filter((_tag: string) => _tag !== tag)];
      tagsUpdated.value = true;
    }

    const currentPassword = ref("");
    const passwordConfirm = ref("");
    const passwordMatchError = ref(false);
    const passwordError = ref(false);

    watch(input, (newInput) => {
      if (field.value === "password") {
        if (newInput && newInput.length < 8) {
          document
            .querySelector('input[name="newPassword"]')
            ?.classList.add("error");
          passwordError.value = true;
        } else {
          document
            .querySelector('input[name="newPassword"]')
            ?.classList.remove("error");
          passwordError.value = false;
        }
      }
    });

    watch(passwordConfirm, (newPasswordConfirm) => {
      if (newPasswordConfirm) {
        if (newPasswordConfirm !== input.value) {
          document
            .querySelector('input[name="passwordConfirm"]')
            ?.classList.add("error");
          passwordMatchError.value = true;
        } else {
          document
            .querySelector('input[name="passwordConfirm"]')
            ?.classList.remove("error");
          passwordMatchError.value = false;
        }
      } else {
        passwordMatchError.value = false;
      }
    });

    return {
      message,
      yesMessage,
      field,
      onCancel,
      onUpdate,
      input,
      newTagInput,
      onFileChanged,
      handleRadioInputChange,
      submitEnabled,
      errorMessage,
      options,
      selectOptionsOpen,
      getCountryName,
      prefilledValue,
      maxLength,
      removeTag,
      currentPassword,
      passwordConfirm,
      passwordMatchError,
      passwordError,
    };
  },
});
</script>

<style lang="scss" scoped>
.text-box {
  @apply w-full
            shadow-sm
            block
            focus:ring-rooom-light
            focus:border-rooom-light
            sm:text-sm
            border-gray-300
            rounded-md;
}
input.error {
  @apply border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500;
}
</style>
