import { Module } from "vuex";
import { RootState } from "@/store/index";
import axios from "axios";

const API_URL = process.env.VUE_APP_BACKEND_URL + "/";

interface Groups {
  groups: any[];
}

const groups: Module<Groups, RootState> = {
  namespaced: true,
  state: {
    groups: [],
  },
  mutations: {
    setGroups(state, groups: any[]) {
      state.groups = groups;
    },
  },
  actions: {
    getGroups({ state, dispatch, rootGetters }, params: any) {
      return axios
        .get(API_URL + `groups?q=${params.query}&sortBy=${params.sort}`, {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          state.groups = response.data.groups;
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "groups/getGroups",
              callbackActionArgs: params,
            },
            { root: true },
          );
        });
    },
    deleteGroups({ commit, dispatch, rootGetters }, groupNames: string[]) {
      return axios
        .delete(API_URL + "groups", {
          headers: rootGetters["auth/authHeader"],
          data: { groupNames: groupNames },
        })
        .then((response) => {
          commit(
            "openAlert",
            {
              type: "success",
              message: "alerts.groups/deleteGroups.200",
              disappearAfter: 3000,
              closeButtonExist: false,
            },
            { root: true },
          );
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "groups/deleteGroups",
              callbackActionArgs: groupNames,
            },
            { root: true },
          );
        });
    },
    getGroup({ dispatch, rootGetters }, groupName: string) {
      return axios
        .get(API_URL + `group/${groupName}`, {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "groups/getGroup",
              callbackActionArgs: groupName,
            },
            { root: true },
          );
        });
    },
    createGroup({ dispatch, rootGetters }, payload: any) {
      return axios
        .post(API_URL + "groups", payload, {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "groups/createGroup",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
    updateGroup({ dispatch, rootGetters }, payload: any) {
      return axios
        .put(API_URL + `group/${payload.groupName}`, payload.payload, {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "groups/updateGroup",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
    addMembersToGroups({ commit, dispatch, rootGetters }, payload: any) {
      return axios
        .post(API_URL + "groups/members", payload, {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          commit(
            "openAlert",
            {
              type: "success",
              message: "alerts.groups/addMembersToGroups.200",
              disappearAfter: 3000,
              closeButtonExist: false,
            },
            { root: true },
          );
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "groups/addMembersToGroups",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
    removeMembersFromGroups({ commit, dispatch, rootGetters }, payload: any) {
      return axios
        .delete(API_URL + "groups/members", {
          headers: rootGetters["auth/authHeader"],
          data: payload,
        })
        .then((response) => {
          commit(
            "openAlert",
            {
              type: "success",
              message: "alerts.groups/removeMembersFromGroups.200",
              disappearAfter: 3000,
              closeButtonExist: false,
            },
            { root: true },
          );
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "groups/removeMembersFromGroups",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
  },
  getters: {},
};

export default groups;
