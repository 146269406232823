<template>
  <div
    class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
  >
    <div class="max-w-md w-full space-y-8">
      <div class="flex flex-col space-y-2 items-center shrink-0 px-4">
        <img class="w-56" src="@/assets/logo.svg" alt="Rooom" />
      </div>
      <form
        class="mt-8 space-y-6"
        action="#"
        method="POST"
        @submit.prevent="onSubmit"
      >
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="email" class="sr-only">{{ $t("email-address") }}</label>
            <input
              v-model="email"
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-rooom-default focus:border-rooom-default focus:z-10 sm:text-sm"
              placeholder="Email address"
            />
          </div>
          <div>
            <label for="password" class="sr-only">{{ $t("password") }}</label>
            <input
              v-model="password"
              id="password"
              name="password"
              type="password"
              autocomplete="current-password"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-rooom-default focus:border-rooom-default focus:z-10 sm:text-sm"
              placeholder="Password"
            />
          </div>
        </div>

        <div class="flex items-center justify-between">
          <div class="text-sm">
            <a
              href="#"
              @click="openForgotPasswordPanel"
              class="font-medium text-rooom-dark hover:text-rooom-default"
            >
              {{ $t("forgot-your-password") }}
            </a>
          </div>
        </div>

        <div>
          <button
            type="submit"
            class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-rooom-light hover:bg-rooom-default focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rooom-default"
          >
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon
                class="h-5 w-5 text-rooom-default group-hover:text-rooom-lighter"
                aria-hidden="true"
              />
            </span>
            {{ $t("sign-in") }}
          </button>
        </div>
      </form>
      <div class="mt-6">
        <div class="relative">
          <div class="absolute inset-0 flex items-center">
            <div class="w-full border-t border-gray-300"></div>
          </div>
          <div class="relative flex justify-center text-sm">
            <span class="px-2 bg-gray-50 text-gray-500"> Or </span>
          </div>
        </div>

        <div class="mt-6">
          <a
            href="#"
            @click="goToRegisterPage"
            class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          >
            Register
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="hidden" id="forgot-password-panel">
    <login-page-forgot-password-panel
      @closeForgotPasswordPanel="closeForgotPasswordPanel"
    />
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { LockClosedIcon } from "@heroicons/vue/20/solid";
import LoginPageForgotPasswordPanel from "@/views/LoginPageForgotPasswordPanel.vue";

export default {
  name: "LoginPage",
  components: {
    LockClosedIcon,
    LoginPageForgotPasswordPanel,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const email = ref("");
    const password = ref("");

    function openForgotPasswordPanel() {
      document.querySelector("#forgot-password-panel")?.classList.add("active");
    }

    function closeForgotPasswordPanel() {
      document
        .querySelector("#forgot-password-panel")
        ?.classList.remove("active");
    }

    function onSubmit() {
      if (email.value && password.value) {
        const payload = {
          email: email.value,
          password: password.value,
        };
        store.dispatch("auth/login", payload).then(() => {
          router.push("/user/orders");
        });
      }
    }

    function goToRegisterPage() {
      router.push("/register");
    }

    return {
      email,
      password,
      onSubmit,
      openForgotPasswordPanel,
      closeForgotPasswordPanel,
      goToRegisterPage,
    };
  },
};
</script>
