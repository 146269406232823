<template>
  <div class="h-screen flex flex-col overflow-hidden">
    <top-banner id="banner" />
    <div v-if="user" class="flex-1 flex overflow-hidden bg-gray-100">
      <div v-show="sidebarOpen">
        <div
          static
          class="fixed inset-0 flex z-40 md:hidden"
          @close="sidebarOpen = false"
          :open="sidebarOpen"
        >
          <transition
            enter="transition-opacity ease-linear duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div
              v-show="sidebarOpen"
              class="fixed inset-0 bg-gray-600 bg-opacity-75"
            />
          </transition>
          <transition
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <div
              v-show="sidebarOpen"
              class="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800"
            >
              <transition
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div
                  v-show="sidebarOpen"
                  class="absolute top-0 right-0 -mr-12 pt-2"
                >
                  <button
                    class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    @click="sidebarOpen = false"
                  >
                    <span class="sr-only">{{ $t("close-sidebar") }}</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </transition>
              <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div class="shrink-0 flex flex-col py-2 items-center px-4">
                  <img class="w-48" src="@/assets/logo.svg" alt="Rooom" />
                </div>
                <nav class="mt-2.5 px-2 space-y-2">
                  <div
                    class="mb-2.5 w-full inline-flex items-center justify-center"
                  >
                    <button
                      @click.prevent="openChangelog"
                      class="baloo-font text-xl text-slate-300 hover:text-slate-200 focus:outline-none"
                    >
                      {{
                        (currentVersion.startsWith("v") ? "" : "v") +
                        currentVersion
                      }}
                    </button>
                  </div>
                  <button
                    v-if="
                      (['customer', 'manager', 'default_manager'].includes(
                        user.role,
                      ) &&
                        user.status === 'approved') ||
                      user.role === 'admin'
                    "
                    @click.prevent="openOrderPlacement"
                    class="w-full flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer bg-rooom-dark hover:bg-opacity-80 focus:outline-none text-white"
                  >
                    <PlusIcon class="mr-3 h-6 w-6 text-gray-300" />{{
                      $t("new-order")
                    }}
                  </button>
                  <router-link
                    v-for="item in navigation"
                    :key="item.name"
                    :to="item.href"
                    :class="[
                      item.name.includes(currentPage)
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer',
                    ]"
                  >
                    <component
                      :is="item.icon"
                      :class="[
                        item.name.includes(currentPage)
                          ? 'text-gray-300'
                          : 'text-gray-400 group-hover:text-gray-300',
                        'mr-4 h-6 w-6',
                      ]"
                      aria-hidden="true"
                    />
                    {{ $t(item.name.toLowerCase()) }}
                  </router-link>
                  <button
                    @click.prevent="logout"
                    class="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer"
                  >
                    <ArrowRightOnRectangleIcon
                      class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                    />
                    {{ $t("logout") }}
                  </button>
                </nav>
              </div>
              <div class="shrink-0 flex">
                <div class="shrink-0 group block w-full">
                  <footer
                    class="bg-transparent mx-auto overflow-hidden py-5 px-2"
                  >
                    <nav
                      class="flex flex-wrap justify-center"
                      aria-label="Footer"
                    >
                      <div
                        class="px-2 py-1 inline-flex items-center justify-center"
                      >
                        <router-link
                          to="/user/privacy"
                          class="text-sm font-medium text-gray-500 hover:text-gray-400"
                          >{{ $t("Privacy Policy") }}</router-link
                        >
                      </div>

                      <div
                        class="px-2 py-1 inline-flex items-center justify-center"
                      >
                        <router-link
                          to="/user/disclaimer"
                          class="text-sm font-medium text-gray-500 hover:text-gray-400"
                          >{{ $t("Disclaimer") }}</router-link
                        >
                      </div>

                      <div
                        class="px-2 py-1 inline-flex items-center justify-center"
                      >
                        <router-link
                          to="/user/terms"
                          class="text-sm font-medium text-gray-500 hover:text-gray-400"
                          >{{ $t("Terms and Conditions") }}</router-link
                        >
                      </div>

                      <div
                        class="px-2 py-1 inline-flex items-center justify-center"
                      >
                        <router-link
                          to="/user/impressum"
                          class="text-sm font-medium text-gray-500 hover:text-gray-400"
                          >Impressum</router-link
                        >
                      </div>
                    </nav>
                    <!-- <p class="mt-3 text-center font-medium text-sm text-gray-400">
                    &copy; 2022 rooom Case Manager, Inc. <br />All rights
                    reserved.
                  </p> -->
                  </footer>
                  <div class="flex items-center bg-gray-700 p-4">
                    <profile-icon
                      :account="user"
                      :darkMode="true"
                      :clickEnabled="false"
                      :showTooltip="false"
                      :size="10"
                      :refresh-count="profileIconRefreshCount"
                    />
                    <div class="ml-3 inline-block truncate">
                      <p class="text-base font-medium text-white truncate">
                        {{ user.first_name + " " + user.last_name }}
                      </p>
                      <div class="mt-1 flex flex-row space-x-1 items-center">
                        <span
                          :class="[
                            user.role === 'customer'
                              ? 'bg-violet-100 text-violet-600'
                              : user.role === 'partner'
                                ? 'bg-amber-100 text-amber-600'
                                : ['manager', 'default_manager'].includes(
                                      user.role,
                                    )
                                  ? 'bg-rooom-lightest text-rooom-default'
                                  : 'bg-gray-100 text-gray-600',
                            'px-1.5 py-.5 inline-flex text-xs leading-5 font-semibold rounded-full mr-1',
                          ]"
                        >
                          {{ $t(user.role) }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <div class="shrink-0 w-14">
            <!-- Force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </div>

      <!-- Static sidebar for desktop -->
      <div class="hidden md:flex md:shrink-0">
        <div class="flex flex-col w-64">
          <!-- Sidebar component, swap this element with another sidebar if you like -->
          <div class="flex flex-col h-0 flex-1 bg-gray-800">
            <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div class="flex flex-col py-2 items-center shrink-0 px-4">
                <img class="w-40" src="@/assets/logo.svg" alt="Rooom" />
              </div>
              <nav class="mt-1.5 flex-1 px-2 bg-gray-800 space-y-2">
                <div
                  class="mb-1.5 w-full inline-flex items-center justify-center"
                >
                  <button
                    @click.prevent="openChangelog"
                    class="baloo-font text-lg text-slate-300 hover:text-slate-200 focus:outline-none"
                  >
                    {{
                      (currentVersion.startsWith("v") ? "" : "v") +
                      currentVersion
                    }}
                  </button>
                </div>
                <button
                  v-if="
                    (['customer', 'manager', 'default_manager'].includes(
                      user.role,
                    ) &&
                      user.status === 'approved') ||
                    user.role === 'admin'
                  "
                  @click.prevent="openOrderPlacement"
                  class="w-full flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer bg-rooom-dark hover:bg-opacity-80 focus:outline-none text-white"
                >
                  <PlusIcon class="mr-3 h-6 w-6 text-gray-300" />{{
                    $t("new-order")
                  }}
                </button>
                <router-link
                  v-for="item in navigation"
                  :key="item.name"
                  :to="item.href"
                  :class="[
                    item.name.includes(currentPage)
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer',
                  ]"
                >
                  <component
                    :is="item.icon"
                    :class="[
                      item.name.includes(currentPage)
                        ? 'text-gray-300'
                        : 'text-gray-400 group-hover:text-gray-300',
                      'mr-3 h-6 w-6',
                    ]"
                    aria-hidden="true"
                  />
                  {{ $t(item.name.toLowerCase()) }}
                </router-link>
                <button
                  @click.prevent="logout"
                  class="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer"
                >
                  <ArrowRightOnRectangleIcon
                    class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                  />
                  {{ $t("logout") }}
                </button>
              </nav>
            </div>
            <div class="shrink-0 flex">
              <div class="shrink-0 w-full group block">
                <footer
                  class="bg-transparent mx-auto overflow-hidden py-5 px-2"
                >
                  <nav
                    class="flex flex-wrap justify-center"
                    aria-label="Footer"
                  >
                    <div
                      class="px-2 py-1 inline-flex items-center justify-center"
                    >
                      <router-link
                        to="/user/privacy"
                        class="text-xs font-medium text-gray-500 hover:text-gray-400"
                        >{{ $t("Privacy Policy") }}</router-link
                      >
                    </div>

                    <div
                      class="px-2 py-1 inline-flex items-center justify-center"
                    >
                      <router-link
                        to="/user/disclaimer"
                        class="text-xs font-medium text-gray-500 hover:text-gray-400"
                        >{{ $t("Disclaimer") }}</router-link
                      >
                    </div>

                    <div
                      class="px-2 py-1 inline-flex items-center justify-center"
                    >
                      <router-link
                        to="/user/terms"
                        class="text-xs font-medium text-gray-500 hover:text-gray-400"
                        >{{ $t("Terms and Conditions") }}</router-link
                      >
                    </div>

                    <div
                      class="px-2 py-1 inline-flex items-center justify-center"
                    >
                      <router-link
                        to="/user/impressum"
                        class="text-xs font-medium text-gray-500 hover:text-gray-400"
                        >Impressum</router-link
                      >
                    </div>
                  </nav>
                  <!-- <p class="mt-3 text-center font-medium text-xs text-gray-400">
                    &copy; 2022 rooom Case Manager, Inc. <br />All rights
                    reserved.
                  </p> -->
                </footer>
                <div class="bg-gray-700 flex items-center p-4">
                  <profile-icon
                    :account="user"
                    :darkMode="true"
                    :clickEnabled="false"
                    :showTooltip="false"
                    :size="10"
                  />
                  <div class="ml-3 inline-block truncate">
                    <p class="text-sm font-medium text-white truncate">
                      {{ user.first_name + " " + user.last_name }}
                    </p>
                    <div class="mt-1 flex flex-row space-x-1 items-center">
                      <role-badge
                        class="mr-0.5"
                        :role="user.role"
                        :darkMode="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-0 flex-1 overflow-hidden">
        <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-rooom-light"
            @click="sidebarOpen = true"
          >
            <span class="sr-only">{{ $t("close-sidebar") }}</span>
            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main class="flex-1 relative z-0 overflow-hidden focus:outline-none">
          <div class="flex flex-col h-full">
            <router-view />
          </div>
        </main>
      </div>
    </div>
    <the-panel
      v-if="panelOpen"
      :message="panelMessage"
      :yesMessage="panelYesMessage"
      :noMessage="panelNoMessage"
      :input="panelInput"
      :inputType="panelInputType"
    />
    <create-group-panel
      v-if="createGroupPanelOpen"
      :propName="createGroupPanelName"
      :propColor="createGroupPanelColor"
      :propMembers="createGroupPanelMembers"
    />
    <select-groups-panel v-if="selectGroupsPanelOpen" />
    <select-accounts-panel v-if="selectAccountsPanelOpen" />
    <profile-update-panel v-if="profileUpdatePanelOpen" />
    <changelog-panel
      v-if="changelogPanelOpen"
      @close="changelogPanelOpen = false"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import CreateGroupPanel from "@/views/GroupCreationPanel.vue";
import SelectGroupsPanel from "@/components/GroupsSelectionPanel.vue";
import SelectAccountsPanel from "@/components/AccountsSelectionPanel.vue";
import {
  Bars4Icon,
  Bars3Icon,
  UsersIcon,
  UserIcon,
  TagIcon,
  XMarkIcon,
  PlusIcon,
  Cog6ToothIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/vue/24/outline";
import ThePanel from "@/components/ThePanel.vue";
import ProfileUpdatePanel from "@/components/ProfileUpdatePanel.vue";
import RoleBadge from "@/components/RoleBadge.vue";
import TopBanner from "@/components/TopBanner.vue";
import ProfileIcon from "@/components/ProfileIcon.vue";
import ChangelogPanel from "@/components/ChangelogPanel.vue";

export default defineComponent({
  name: "LoggedInLayout",
  components: {
    ThePanel,
    CreateGroupPanel,
    Bars3Icon,
    UserIcon,
    TagIcon,
    UsersIcon,
    XMarkIcon,
    PlusIcon,
    Cog6ToothIcon,
    SelectGroupsPanel,
    SelectAccountsPanel,
    ProfileUpdatePanel,
    RoleBadge,
    TopBanner,
    ProfileIcon,
    ArrowRightOnRectangleIcon,
    ChangelogPanel,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const user = computed(() => {
      return store.state.auth.user;
    });

    // Panel
    const panelOpen = computed(() => {
      return store.state.panel.open;
    });
    const panelMessage = computed(() => {
      return store.state.panel.message;
    });
    const panelYesMessage = computed(() => {
      return store.state.panel.yesMessage;
    });
    const panelNoMessage = computed(() => {
      return store.state.panel.noMessage;
    });
    const panelInput = computed(() => {
      return store.state.panel.input;
    });
    const panelInputType = computed(() => {
      return store.state.panel.inputType;
    });

    // Create Group Panel
    const createGroupPanelOpen = computed(() => {
      return store.state.createGroupPanel.open;
    });
    const createGroupPanelName = computed(() => {
      return store.state.createGroupPanel.name;
    });
    const createGroupPanelColor = computed(() => {
      return store.state.createGroupPanel.color;
    });
    const createGroupPanelMembers = computed(() => {
      return store.state.createGroupPanel.members;
    });

    // Select Groups or Accounts Panel
    const selectGroupsPanelOpen = computed(() => {
      return store.state.panels.selectGroupsPanel.open;
    });
    const selectAccountsPanelOpen = computed(() => {
      return store.state.panels.selectAccountsPanel.open;
    });

    // Profile Update Panel
    const profileUpdatePanelOpen = computed(
      () => store.state.auth.updatePanel.open,
    );

    const sidebarOpen = ref(false);
    const currentPage = computed(() => {
      return store.state.currentPage;
    });

    const navigation = ref([
      { name: "Orders", href: { name: "Orders" }, icon: Bars4Icon },
    ]);

    if (["admin", "manager", "default_manager"].includes(user.value.role)) {
      navigation.value.push(
        {
          name: "Accounts",
          href: { name: "Accounts" },
          icon: UsersIcon,
        },
        {
          name: "Groups",
          href: { name: "Groups" },
          icon: TagIcon,
        },
      );
    }

    navigation.value.push({
      name: "Settings",
      href: { name: "Settings" },
      icon: Cog6ToothIcon,
    });

    function logout() {
      store.dispatch("auth/logout");
    }

    function openOrderPlacement() {
      router.push("/user/order");
    }

    const currentVersion = computed(() => store.state.auth.currentVersion);
    const changelogPanelOpen = ref(false);
    function openChangelog() {
      changelogPanelOpen.value = true;
    }

    const profileIconRefreshCount = ref(0);

    watch(
      user,
      (newVal: any, oldVal: any) => {
        // user is logged out when newVal is null
        if (newVal && oldVal) {
          if (oldVal.image && newVal.image) {
            if (newVal.image != oldVal.image) {
              profileIconRefreshCount.value++;
            }
          }
        }
      },
      { immediate: true },
    );

    return {
      user,
      navigation,
      sidebarOpen,
      currentPage,
      logout,
      panelOpen,
      panelMessage,
      panelYesMessage,
      panelNoMessage,
      panelInput,
      panelInputType,
      createGroupPanelOpen,
      createGroupPanelName,
      createGroupPanelColor,
      createGroupPanelMembers,
      selectGroupsPanelOpen,
      selectAccountsPanelOpen,
      openOrderPlacement,
      profileUpdatePanelOpen,
      openChangelog,
      currentVersion,
      changelogPanelOpen,
      profileIconRefreshCount,
    };
  },
});
</script>
