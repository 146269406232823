<template>
  <div
    id="alert-box"
    class="border-l-4 p-4 sm:rounded-md sm:left-1/2 sm:transform sm:-translate-x-1/2 fixed top-0 sm:top-5 z-50 w-full sm:w-auto"
  >
    <div class="flex">
      <div class="shrink-0" id="alert-svg">
        <ExclamationTriangleIcon v-if="type === 'warning'" class="h-5 w-5" />
        <XCircleIcon v-if="type === 'error'" class="h-5 w-5" />
        <CheckCircleIcon v-if="type === 'success'" class="h-5 w-5" />
      </div>
      <div class="ml-3">
        <p id="alert-message" class="text-sm font-medium">
          {{ $t(message) }}
        </p>
      </div>
      <div v-if="closeButtonExist" class="ml-auto pl-3">
        <div class="-mx-1.5 -my-1.5">
          <button
            @click.prevent="closeAlert"
            id="alert-close-button"
            class="inline-flex rounded-md p-1.5 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2"
          >
            <span class="sr-only">Dismiss</span>
            <XMarkIcon class="h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onUpdated, onMounted } from "vue";
import { useStore } from "vuex";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
  XCircleIcon,
  CheckCircleIcon,
} from "@heroicons/vue/20/solid";

export default defineComponent({
  name: "TheAlert",
  props: {
    type: {
      type: String,
      default: "warning",
    },
    message: String,
    closeButtonExist: {
      type: Boolean,
      default: true,
    },
    disappearAfter: {
      type: Number,
      default: 3000,
    },
  },
  components: {
    ExclamationTriangleIcon,
    XMarkIcon,
    XCircleIcon,
    CheckCircleIcon,
  },
  setup(props) {
    const store = useStore();
    function closeAlert() {
      store.commit("closeAlert");
    }

    onMounted(() => {
      const type = props.type;
      document.querySelector("#alert-box")?.classList.add(type);
      document.querySelector("#alert-svg")?.classList.add(type);
      document.querySelector("#alert-message")?.classList.add(type);
      if (props.closeButtonExist)
        document.querySelector("#alert-close-button")?.classList.add(type);
      if (props.disappearAfter > 0) {
        setTimeout(() => {
          closeAlert();
        }, props.disappearAfter);
      }
    });

    onUpdated(() => {
      const type = props.type;
      document.querySelector("#alert-box")?.classList.add(type);
      document.querySelector("#alert-svg")?.classList.add(type);
      document.querySelector("#alert-message")?.classList.add(type);
      if (props.closeButtonExist)
        document.querySelector("#alert-close-button")?.classList.add(type);
      if (props.disappearAfter > 0) {
        setTimeout(() => {
          closeAlert();
        }, props.disappearAfter);
      }
    });

    return {
      closeAlert,
    };
  },
});
</script>

<style lang="scss" scoped>
#alert-box.warning {
  @apply bg-amber-50 border-amber-400;
}

#alert-box.error {
  @apply bg-red-50 border-red-400;
}

#alert-box.success {
  @apply bg-emerald-50 border-emerald-400;
}

#alert-svg.warning {
  @apply text-amber-400;
}

#alert-svg.error {
  @apply text-red-400;
}

#alert-svg.success {
  @apply text-emerald-400;
}

#alert-message.warning {
  @apply text-amber-700;
}

#alert-message.error {
  @apply text-red-700;
}

#alert-message.success {
  @apply text-emerald-700;
}

#alert-close-button.warning {
  @apply bg-amber-50 text-amber-500 hover:bg-amber-100 focus:ring-offset-amber-50 focus:ring-amber-600;
}

#alert-close-button.error {
  @apply bg-red-50 text-red-500 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600;
}

#alert-close-button.success {
  @apply bg-emerald-50 text-emerald-500 hover:bg-emerald-100 focus:ring-offset-emerald-50 focus:ring-emerald-600;
}
</style>
