export default {
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
  "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortieren"])},
  "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
  "dont-know": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiß es nicht"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keine"])},
  "freely-modelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["frei modelliert"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farbe"])},
  "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder"])},
  "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren"])},
  "deactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktivieren"])},
  "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
  "select-groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen auswählen"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
  "last-updated-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zuletzt aktualisiert am"])},
  "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplizieren"])},
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptieren"])},
  "by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
  "created-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erstellt am"])},
  "error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten."])},
  "new-changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Changelog"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau"])},
  "update-changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changelog aktualisieren"])},
  "last-modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt modifiziert"])},
  "last-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt erstellt"])},
  "email-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
  "forgot-your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
  "register-as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriere Als"])},
  "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
  "position-at-the-company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stelle im Unternehmen"])},
  "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
  "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
  "second-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zweite Telefonnummer"])},
  "phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
  "second-phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zweite Telefonnummer"])},
  "profile-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilbild"])},
  "confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort bestätigen"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
  "as-a-customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["als Kunde/n"])},
  "as-a-partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["als Partner/in"])},
  "as-an-admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["als ein Admin"])},
  "as-a-manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["als Manager/in"])},
  "email-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail ist erforderlich."])},
  "first-name-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname ist erforderlich."])},
  "last-name-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname ist erforderlich."])},
  "password-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ist erforderlich."])},
  "passwords-must-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwörter müssen übereinstimmen."])},
  "password must be at least 8 characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort muss mindestens 8 Zeichen lang sein."])},
  "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner"])},
  "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
  "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])},
  "upload-an-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ein Bild hochladen"])},
  "upload-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien hochladen"])},
  "or-drag-and-drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder per Drag & Drop"])},
  "up-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis zu"])},
  "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["optional"])},
  "forgot-password-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie die E-Mail-Adresse ein, mit der Sie sich registriert haben. Wir senden Ihnen einen Link zum Zurücksetzen des Passworts."])},
  "reset-your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Passwort zurücksetzen"])},
  "check-your-inbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfen Sie Ihren Posteingang"])},
  "back-to-login-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Anmeldeseite"])},
  "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut senden"])},
  "forgot-password-response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danke! Wenn die E-Mail als registriert gefunden wurde, erhalten Sie eine E-Mail, um Ihr Passwort zurückzusetzen."])},
  "reset-your-password-for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setzen Sie Ihr Passwort zurück für"])},
  "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
  "reset-password-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort wurde erfolgreich zurückgesetzt."])},
  "reset-password-link-expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Link zum Zurücksetzen ist abgelaufen."])},
  "reset-password-already-reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben das Passwort bereits zurückgesetzt."])},
  "reset-password-unauthorized-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbefugter Zugriff"])},
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellungen"])},
  "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konten"])},
  "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen"])},
  "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellung"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmeldung"])},
  "open-sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seitenleiste öffnen"])},
  "close-sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seitenleiste schließen"])},
  "new-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Bestellung"])},
  "pending-banner-message-main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte aktualisieren Sie Ihr Profil im Menü Einstellungen für die weitere Verwendung."])},
  "pending-banner-message-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte aktualisieren Sie Ihr Profil."])},
  "approving-banner-message-main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danke, dass Sie Ihr Profil aktualisiert haben. Bitte warten Sie, bis es bald von uns genehmigt wird."])},
  "approving-banner-message-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Profil wird bald genehmigt."])},
  "go-to-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehe zu den Einstellungen"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle"])},
  "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webseite"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße und Hausnummer"])},
  "notification-setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungseinstellung"])},
  "send-email-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Benachrichtigungen senden"])},
  "send-email-notifications-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie diese Option aktivieren, erhalten Sie E-Mails zu unseren Funktionsaktualisierungen."])},
  "partner-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner-Informationen"])},
  "company-size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensgröße"])},
  "partner-tags-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompetenz-Tags"])},
  "partner-tags-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie Tags für Ihr Fachgebiet hinzu"])},
  "language-setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spracheinstellung"])},
  "app-setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App-Einstellung"])},
  "payment-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsmethode"])},
  "no-payment-method-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Zahlungsmethode festgelegt"])},
  "input-value-must-be-valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Eingabewert muss gültig sein."])},
  "update-user-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerrolle aktualisieren"])},
  "update-first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vornamen aktualisieren"])},
  "update-last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname aktualisieren"])},
  "update-phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer aktualisieren"])},
  "update-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website aktualisieren"])},
  "update-position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position aktualisieren"])},
  "update-profile-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilbild aktualisieren"])},
  "update-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung aktualisieren"])},
  "update-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land aktualisieren"])},
  "update-city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt aktualisieren"])},
  "update-postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl aktualisieren"])},
  "update-street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße und Hausnummer aktualisieren"])},
  "choose-a-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie ein Land"])},
  "update-iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN aktualisieren"])},
  "update-company-size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensgröße aktualisieren"])},
  "add-a-new-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein neues Tag hinzufügen"])},
  "auth-getStripeApiKey-412": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Einrichtung der Zahlungsmethode ist etwas schief gelaufen."])},
  "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erforderlich"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern"])},
  "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
  "current-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelles Passwort"])},
  "confirm-new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort bestätigen"])},
  "password-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort muss mindestens 8 Zeichen lang sein."])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "fix-sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seitenleiste fixieren"])},
  "search-tags-to-filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Tags filtern"])},
  "search-members-to-filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder zum Filtern suchen"])},
  "Filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "Showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeigt"])},
  "Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])},
  "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
  "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnisse"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neu"])},
  "order-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestell-Token"])},
  "managers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
  "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner"])},
  "assign-members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder zuweisen"])},
  "search-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellungen suchen"])},
  "last-ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt bestellt"])},
  "id-(a-z)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID (A-Z)"])},
  "id-(z-a)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID (Z-A)"])},
  "order-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelldatum"])},
  "contractor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmer"])},
  "orders-delete-panel-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie diese Aufträge löschen wollen?"])},
  "ordered-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellt von"])},
  "search-for-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche nach Konten"])},
  "search-for-accounts-or-groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche nach Konten oder Gruppen"])},
  "deleted-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gelöschtes Konto"])},
  "our-price-offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unser Preisangebot"])},
  "Filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefiltert"])},
  "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])},
  "Exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ausschließen"])},
  "Include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einschließen"])},
  "Archive Only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Archiv"])},
  "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inaktiv"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ausstehend"])},
  "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bestätigt"])},
  "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf"])},
  "submitted_incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unvollständig"])},
  "submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übermittelt"])},
  "offering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebote"])},
  "negotiating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verhandlung"])},
  "contracting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrag"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertiggestellt"])},
  "payment_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung fehlgeschlagen"])},
  "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storniert"])},
  "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeitung"])},
  "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfung"])},
  "incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unvollständig"])},
  "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiv"])},
  "archive-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiviere Bestellung"])},
  "unarchive-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht archivierte Bestellung"])},
  "cancel-reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund für die Stornierung"])},
  "accepted, awaiting payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angenommen, in Erwartung der Zahlung"])},
  "Registration confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung bestätigt"])},
  "Thanks for your registration. You are now ready to login.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für Ihre Registrierung. Sie können sich jetzt anmelden."])},
  "You have already confirmed the registration.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben die Anmeldung bereits bestätigt."])},
  "Invalid access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Zugang"])},
  "No registration request found with this email.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde keine Registrierungsanfrage mit dieser E-Mail gefunden."])},
  "add-members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder hinzufügen"])},
  "assign-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellungen zuweisen"])},
  "search-groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen suchen"])},
  "search-for-groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche nach Gruppen"])},
  "groups-delete-panel-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie diese Gruppen löschen wollen?"])},
  "groups-get-alert-403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf Gruppen kann nur ein Administrator zugreifen."])},
  "name-(a-z)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name (A-Z)"])},
  "name-(z-a)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name (Z-A)"])},
  "new-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Gruppe"])},
  "add-a-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Mitglied hinzufügen"])},
  "group-create-alert-200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Gruppe wurde erfolgreich erstellt."])},
  "group-create-alert-403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen können nur von einem Administrator erstellt werden."])},
  "select-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konten auswählen"])},
  "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisen"])},
  "set-as-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Standard eingestellt"])},
  "account-activate-button-tippy-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren Sie das Konto ohne die E-Mail-Bestätigung des Benutzers."])},
  "email-confirmation-bypass-checkbox-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren Sie das Konto ohne E-Mail-Bestätigung."])},
  "login-post-401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche Anmeldeinformationen."])},
  "login-post-403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte vervollständigen Sie Ihre Anmeldung über den Bestätigungslink, den wir an Ihre E-Mail-Adresse geschickt haben."])},
  "login-post-405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbanntes Konto."])},
  "register-put-200-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung bestätigt"])},
  "register-put-200-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für Ihre Registrierung. Sie können sich jetzt anmelden."])},
  "register-put-404-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Zugang"])},
  "register-put-404-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde keine Registrierungsanfrage mit dieser E-Mail gefunden."])},
  "register-put-409-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung bestätigt"])},
  "register-put-409-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben die Registrierung bereits bestätigt."])},
  "register-put-419-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelaufener Link"])},
  "register-put-419-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops. Es ist schon zu lange her. Bitte registrieren Sie sich erneut."])},
  "accounts-create-alert-200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Konto wurde erfolgreich erstellt."])},
  "register-post-200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für Ihre Anmeldung! Bitte bestätigen Sie Ihre Anmeldung, indem Sie dem Link folgen, den wir Ihnen an Ihre E-Mail-Adresse geschickt haben."])},
  "register-post-409": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail bereits in Gebrauch."])},
  "add-to-groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu Gruppen hinzufügen"])},
  "search-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konten suchen"])},
  "accounts-add-admin-error-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einem Admin-Konto können keine Gruppen zugewiesen werden."])},
  "accounts-delete-panel-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie diese Konten löschen wollen?"])},
  "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genehmigen"])},
  "disapprove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
  "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbieten"])},
  "unban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entsperren"])},
  "q-cad-have-color-texture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Ihre CAD-Daten Farb- und/oder -Texturinformationen?"])},
  "q-addition-object-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie zusätzlich Fotos?"])},
  "q-fm-have-texture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie Texturen?"])},
  "q-fm-have-pbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PBR Texturen (base color, metallness, roughness, etc.) vorhanden?"])},
  "new-accessible-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue verfügbare Bestellungen"])},
  "add-an-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eine Bestellung hinzufügen"])},
  "group-delete-panel-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie diese Gruppe löschen wollen?"])},
  "new-group-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Gruppenname"])},
  "new-group-color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Gruppenfarbe"])},
  "group-update-200-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Gruppe wurde erfolgreich aktualisiert."])},
  "group-update-403-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Gruppe kann nur von einem Administrator aktualisiert werden."])},
  "group-delete-200-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Gruppe wurde erfolgreich gelöscht."])},
  "search-for-members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche nach Mitgliedern"])},
  "accessible-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbare Bestellungen"])},
  "search-for-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche nach Bestellungen"])},
  "switch-to-processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Verarbeitung wechseln"])},
  "switch-to-reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Überprüfung wechseln"])},
  "changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geändert auf"])},
  "with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit"])},
  "marked-as-internal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als intern markiert"])},
  "start-processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verarbeitung starten"])},
  "set-frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eingestellte Frequenz"])},
  "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigung"])},
  "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein"])},
  "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus"])},
  "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täglich"])},
  "every-3-days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle 3 Tage"])},
  "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wöchentlich"])},
  "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlich"])},
  "markup-product-snapshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product-Snapshot annotieren"])},
  "markup-snapshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Schnappschuss machen"])},
  "new-change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neue Änderung"])},
  "new-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neue Änderungen"])},
  "new-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neue Nachricht"])},
  "new-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neue Nachrichten"])},
  "no-new-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt keine neuen Nachrichten."])},
  "update-payment-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr letzter Zahlungsversuch ist fehlgeschlagen. Bitte aktualisieren Sie Ihre Karte in den Einstellungen."])},
  "update-payment-method-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren Sie Ihre Zahlungsmethode."])},
  "d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["t"])},
  "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
  "update-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title aktualisieren"])},
  "update-rooom-object_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooom Object Id aktualisieren"])},
  "final-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endpreis"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
  "confirm-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung bestätigen"])},
  "payment-confirm-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachdem die endgültige Version des Produkts ausgewählt wurde, wird Ihre gespeicherte Karte belastet."])},
  "payment-confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung erfolgreich bestätigt."])},
  "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ausgewählt"])},
  "add-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien hinzufügen"])},
  "update-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktaktualisierung"])},
  "no-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Produkte"])},
  "new-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Produkt"])},
  "pass-for-customer-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass für Kundenbewertung"])},
  "add-new-version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Version hinzufügen"])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt"])},
  "mark-contracts-as-signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verträge als unterschrieben markieren"])},
  "mark-as-signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["als unterschrieben markieren"])},
  "contract-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der hier hochgeladene Vertrag muss schließlich von beiden Parteien unterzeichnet werden."])},
  "update-contract-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsdateien aktualisieren"])},
  "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrag"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen"])},
  "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deselektieren"])},
  "your-offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Angebot"])},
  "update-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis aktualisieren"])},
  "update-delivery-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liefertermin aktualisieren"])},
  "open-for-offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen für Angebote"])},
  "cancel-the-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bestellung Stonieren"])},
  "close-the-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bestellung abschließen"])},
  "scan-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D-Scan-Informationen"])},
  "update-scan-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan-Dateien aktualisieren"])},
  "update-product-viewer-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Viewer ID aktualisieren"])},
  "show-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr anzeigen"])},
  "mark-as-ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als bereit markieren"])},
  "product-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktinformation"])},
  "customer-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenbeschreibung"])},
  "update-customer-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenbeschreibung aktualisieren"])},
  "customer-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundendateien"])},
  "update-customer-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundendateien aktualisieren"])},
  "custom-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefiniertes Feld"])},
  "due-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fällig am"])},
  "order-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellprofil"])},
  "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
  "update-due-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fälligkeitsdatum aktualisieren"])},
  "update-reference-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenz-ID aktualisieren"])},
  "update-budget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budget aktualisieren"])},
  "update-tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags aktualisieren"])},
  "update-animation-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animationsbeschreibung aktualisieren"])},
  "price-update-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Aktualisierung wird sich auf den Preis auswirken."])},
  "payment-successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung erfolgreich"])},
  "go-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurückgehen"])},
  "payment-canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung storniert"])},
  "billing-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details zur Rechnungsstellung"])},
  "card-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartennummer"])},
  "card-expiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablauf der Karte"])},
  "billing-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land der Rechnungsstellung"])},
  "postal-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
  "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitäten"])},
  "Order placed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung aufgegeben"])},
  "Case opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fall eröffnet"])},
  "Offer submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot eingereicht"])},
  "Offer accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot akzeptie rt"])},
  "Product uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt hochgeladen"])},
  "Product approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt genehmigt"])},
  "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentare"])},
  "make-a-comment-public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen öffentlichen Kommentar abgeben"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
  "add-a-comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einen Kommentar hinzufügen"])},
  "offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebote"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
  "delivery-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liefertermin"])},
  "deleted-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelöschter Benutzer"])},
  "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angenommen"])},
  "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelehnt"])},
  "new-offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Angebot"])},
  "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokal"])},
  "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot"])},
  "price-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Preis ist erforderlich."])},
  "past-date-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Datum kann nicht in die Vergangenheit gelegt werden."])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung"])},
  "ordered-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellt am"])},
  "close-the-case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen Sie den Fall"])},
  "order-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details zur Bestellung"])},
  "3d-data-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D-Datentyp"])},
  "level-of-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaillierungsgrad"])},
  "level-of-detail-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie detailliert soll Ihr 3D-Modell dargestellt werden?"])},
  "inner-structure-exists-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Ihr Objekt ein Innenleben?"])},
  "logos-included-in-the-object": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In das Objekt einbezogene Logos"])},
  "logo-description-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo aktualisieren Beschreibung"])},
  "color-description-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farbe aktualisieren Beschreibung"])},
  "text-description-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Textbeschreibung aktualisieren"])},
  "special-details-description-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spezielle Details aktualisieren Beschreibung"])},
  "first-name-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vornamen aktualisieren"])},
  "last-name-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname aktualisieren"])},
  "position-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position aktualisieren"])},
  "email-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail aktualisieren"])},
  "phone-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer aktualisieren"])},
  "second-phone-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweite Telefonnummer aktualisieren"])},
  "inner-structure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innere Struktur"])},
  "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existiert"])},
  "undecided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unentschlossen"])},
  "3d-data-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D-Daten-Dateien"])},
  "(with color and/or texture)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(mit Farbe und/oder Textur)"])},
  "(with PBR texture)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(mit PBR-Textur)"])},
  "(with texture)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(mit Textur)"])},
  "upload-new-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Datei hochladen"])},
  "Files with Color Representation (e.g. sketches, drawings)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien mit Farbdarstellung (z. B. Skizzen, Zeichnungen)"])},
  "object-image-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektbilddateien"])},
  "color-authenticity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farbechtheit"])},
  "displaying-texts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte anzeigen"])},
  "special-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besondere Details"])},
  "contact-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt Details"])},
  "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modell"])},
  "open-the-case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnen Sie den Fall"])},
  "make-an-offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Angebot machen"])},
  "upload-a-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Produkt hochladen"])},
  "finalize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertigstellen"])},
  "re-upload-a-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Produkt erneut hochladen"])},
  "approve-&-pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genehmigen & Bezahlen"])},
  "reopen-the-case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederaufnahme des Verfahrens"])},
  "orders-form-update-200-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelldaten erfolgreich aktualisiert."])},
  "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einfach"])},
  "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mittel"])},
  "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["komplex"])},
  "close-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details schließen"])},
  "open-full-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollständige Details öffnen"])},
  "thanks-for-your-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für Ihre Bestellung!"])},
  "you-can-view-your-order-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können die Details Ihrer Bestellung einsehen"])},
  "you-can-view-your-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können Ihre Bestellungen einsehen"])},
  "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier"])},
  "Please check your email to set a password for your account on our": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte prüfen Sie Ihre E-Mail, um ein Passwort für Ihr Konto auf unserer"])},
  "Order Your 3D Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellen Sie Ihr 3D-Modell"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zurück"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weiter"])},
  "How detailed should your 3D model be displayed?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie detailliert soll Ihr 3D-Modell dargestellt werden?"])},
  "The desired level of detail influences the price.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der gewünschte Detaillierungsgrad beeinflusst den Preis."])},
  "lod-simple-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Beschreibung"])},
  "lod-medium-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Beschreibung"])},
  "lod-complex-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Beschreibung"])},
  "Does your object have an inner life?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Ihr Objekt ein Innenleben, dass Sie dargestellt haben möchten?"])},
  "Objects with an inner life has to be web-enabled, thus more difficult to display.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekte mit einem Innenleben müssen webfähig sein, was ihre Darstellung erschwert."])},
  "Inner Life": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innenleben"])},
  "Upload pictures of your object in all directions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie bitte Ihre 3D-Daten mit allen vorhandenen Texturen hoch"])},
  "The image qualities may influence the quality of the final 3D model.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bildqualität kann die Qualität des endgültigen 3D-Modells beeinflussen."])},
  "If there are 3D specialists employed at your company, they can be referred here.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn in Ihrem Unternehmen 3D-Spezialisten beschäftigt sind, können diese hier genannt werden."])},
  "Upload Additional Data of the Object": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Daten des Objekts hochladen"])},
  "This information speeds up the modeling process and ensures an accurate representation of your object.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Informationen beschleunigen den Modellierungsprozess und gewährleisten eine genaue Darstellung Ihres Objekts."])},
  "Logos Included in the Object": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In das Objekt einbezogene Logos"])},
  "Color Authenticity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farbechtheit"])},
  "Displaying Texts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte anzeigen"])},
  "Special Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besondere Details"])},
  "Upload Your Object Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen Ihrer Objektdaten"])},
  "Here you can upload your 3D Data, either in CAD format or freely-modelled.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können Sie Ihre 3D-Daten hochladen, entweder im CAD-Format oder frei modelliert."])},
  "Do you already have a 3D data?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie bereits 3D-Daten?"])},
  "What type is your 3D data?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcher Art sind Ihre 3D-Daten?"])},
  "3D Data Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D-Datentyp"])},
  "Upload your 3D data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie Ihre 3D-Daten hoch"])},
  "with PBR Textures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit PBR-Texturen"])},
  "with the texture information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit den Texturinformationen"])},
  "Are PBR Textures available? (e.g. base color, metalness, roughness)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind PBR-Texturen verfügbar? (z. B. Grundfarbe, Metallizität, Rauheit)"])},
  "Do you have texture information?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie Informationen zur Textur?"])},
  "Upload data with color represenation (e.g. sketches, drawings)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen von Daten mit Farbdarstellung (z. B. Skizzen, Zeichnungen)"])},
  "Do you have additional object images?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie zusätzliche Objektbilder?"])},
  "Does your CAD data have color and/or texture information?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enthalten Ihre CAD-Daten Farb- und/oder Texturinformationen?"])},
  "Upload your CAD data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie Ihre CAD-Daten hoch"])},
  "e.g. The desired format is .png with transparency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z.B. Das gewünschte Format ist png mit Transparenz"])},
  "e.g. Font names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z.B. Schriftartennamen"])},
  "e.g. Display contents such as pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z.B. Inhalte wie Bilder anzeigen"])},
  "e.g. Any sketches or design data (RGB or RAL)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z.B. beliebige Skizzen oder Designdaten (RGB oder RAL)"])},
  "position-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position ist erforderlich."])},
  "email must be a valid email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail muss eine gültige E-Mail-Adresse sein."])},
  "Offer unaccepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebotsannahme storniert"])},
  "Product re-uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt neu hochgeladen"])},
  "to-login-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Login-Seite"])},
  "Customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
  "Customer Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenkonten"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
  "draft-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn diese Option aktiviert ist, wird die Bestellung erst bearbeitet, wenn Sie sie als fertig markiert haben."])},
  "which-order-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellmethode wählen"])},
  "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form"])},
  "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
  "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
  "order-form-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausfüllen und abschicken."])},
  "order-file-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie eine Excel-Datei und zugehörige Dateien hoch."])},
  "example-excel-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beispiel-excel-datei.xlsx"])},
  "order-link-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie einen Link zu dem Ordner in Ihrem Cloud-Speicher frei."])},
  "order-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bestellen"])},
  "reference-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenz-ID"])},
  "order-form-reference-id-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine ID, die für die Suche in der Bestellung verwendet wird."])},
  "animation-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation erforderlich?"])},
  "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien"])},
  "due-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fälligkeitsdatum"])},
  "due-date-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Fälligkeitsdatum muss in der Zukunft liegen."])},
  "budget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budget"])},
  "custom-tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierte Tag"])},
  "order-form-tags-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags können für die Suche und Filterung Ihrer Bestellungen nützlich sein."])},
  "file-size-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Gesamtgröße der Dateien übersteigt das Limit."])},
  "mark-as-draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["als Entwurf markieren"])},
  "new-custom-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues benutzerdefiniertes Feld"])},
  "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feld"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wert"])},
  "custom-fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierte Felder"])},
  "order-form-custom-fields-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie der Bestellung weitere Schlüssel-Wert-Paare hinzu."])},
  "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key"])},
  "animation-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation Beschreibung"])},
  "mark-as-internal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als intern markieren"])},
  "mark-as-internal-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne Bestellungen durchlaufen einen vereinfachten Prozess ohne die Abgabe von Angeboten und die Erstellung von Verträgen."])},
  "new-order-popup-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Bestellung aufgeben"])},
  "new-order-popup-description-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen bei unserem Bestellformular für Produktverbesserungen!"])},
  "new-order-popup-description-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie unzufrieden mit unserem digitalen Produkt? Teilen Sie uns Ihre Meinung mit, wie wir es verbessern können. Erwarten Sie innerhalb von 3 Arbeitstagen ein Angebot für eine individuelle Verbesserung sowie einen voraussichtlichen Zeitplan für das Endergebnis."])},
  "new-order-popup-description-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z.B. Das Ergebnis ist nicht das, was ich erwartet habe..."])},
  "order-overview-popup-submitted-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir danken Ihnen! Ihre Verbesserungsanfrage ist eingegangen. Sie können innerhalb von 3 Werktagen mit unserer Rückmeldung und einem Kostenvoranschlag rechnen. Wir schätzen Ihren Beitrag zur Verbesserung unseres Produkts!"])},
  "Order archived.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung archiviert."])},
  "Order unarchived.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung nicht archiviert."])},
  "Updated the due date.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fälligkeitsdatum aktualisiert."])},
  "Updated the reference ID.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenz-ID aktualisiert."])},
  "Updated the budget.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Budget wurde aktualisiert."])},
  "Updated the tags.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Tags wurden aktualisiert."])},
  "Updated the customer description.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kundenbeschreibung wurde aktualisiert."])},
  "Updated the animation requirement.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Animationsanforderung wurde aktualisiert."])},
  "Marked the order as internal.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bestellung als intern markiert."])},
  "Marked the order as non-internal.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bestellung als nicht intern markiert."])},
  "Updated the animation description.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Beschreibung der Animation aktualisiert."])},
  "Activated the 3d scan information section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den Abschnitt mit den 3D-Scan-Informationen aktiviert."])},
  "Deactivated the 3d scan information section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den Abschnitt mit den 3D-Scan-Informationen deaktiviert."])},
  "Updated the title.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den Titel aktualisiert."])},
  "Deleted a customer file.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Kundendatei gelöscht."])},
  "Updated the customer files.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kundendateien aktualisiert."])},
  "Added a new custom field.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein neues benutzerdefiniertes Feld hinzugefügt."])},
  "Updated a custom field.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein benutzerdefiniertes Feld aktualisiert."])},
  "Removed a custom field.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein benutzerdefiniertes Feld entfernt."])},
  "Updated the description for 3d scan information.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Beschreibung für 3D-Scan-Informationen aktualisiert."])},
  "Deleted a scan file.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Scandatei gelöscht."])},
  "Disconnect rooom files from the Case Manager order.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raumdateien vom Case Manager-Auftrag trennen."])},
  "Disconnect the rooom file from the Case Manager order.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Verbindung zwischen der Raumdatei und der Case Manager-Bestellung trennen."])},
  "Updated the 3d scan files.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die 3D-Scandateien wurden aktualisiert."])},
  "Updated the product viewer ID for 3d scan data.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Produkt-Viewer-ID für 3D-Scandaten wurde aktualisiert."])},
  "Selected an offer.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Angebot ausgewählt."])},
  "Deselected an offer.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot abgewählt."])},
  "Canceled the order.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung storniert."])},
  "Marked the order as ready.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bestellung als bereit markiert."])},
  "Marked the order as draft.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bestellung als Entwurf markiert."])},
  "Opened the case for offers.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den Case für Angebote geöffnet."])},
  "Updated the order status to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den Bestellstatus aktualisiert auf"])},
  "Offer unaccepted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot nicht angenommen."])},
  "Case opened.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case eröffnet."])},
  "Offer accepted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot angenommen."])},
  "Product approved.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt genehmigt."])},
  "Submitted an offer.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot abgegeben."])},
  "Removed the offer.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Angebot entfernt."])},
  "Updated the offer.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Angebot aktualisiert."])},
  "Payment completed.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung abgeschlossen."])},
  "Confirmed the payment.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung bestätigt."])},
  "Payment has succesfully done.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Zahlung wurde erfolgreich durchgeführt."])},
  "Payment has failed.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Zahlung ist fehlgeschlagen."])},
  "Updated the contract price.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den Vertragspreis aktualisiert."])},
  "Updated the contract files.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Vertragsdateien aktualisiert."])},
  "Added a new product.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein neues Produkt hinzugefügt."])},
  "Deleted the product.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Produkt gelöscht."])},
  "Selected the final product version.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die endgültige Produktversion ausgewählt."])},
  "Selected the final product version and confirmed the payment.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die endgültige Produktversion ausgewählt und die Zahlung bestätigt."])},
  "Changed the final product version.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die endgültige Produktversion wurde geändert."])},
  "Updated a product status.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Produktstatus aktualisiert."])},
  "Accepted the offer.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Angebot angenommen."])},
  "Updated the product title.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den Produkttitel aktualisiert."])},
  "Updated the product viewer ID.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die product-viewer ID wurde aktualisiert."])},
  "Updated the product description.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Produktbeschreibung wurde aktualisiert."])},
  "Updated product files.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktdateien aktualisiert."])},
  "The customer declined the offer, canceling the order.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der/Die Kunde(in)  hat das Angebot abgelehnt und die Bestellung storniert."])},
  "Privacy Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzerklärung"])},
  "Disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haftungsausschluss"])},
  "Terms and Conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Geschäftsbedingungen"])},
  "alerts": {
    "orders/placeOrder": {
      "400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bestellung ist aufgrund einer ungültigen Anfrage fehlgeschlagen."])},
      "406": {
        "excel-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei dem folgenden Eintrag ist ein Fehler aufgetreten: "])}
      }
    },
    "orders/deleteOrders": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung(en) erfolgreich gelöscht."])},
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keine Berechtigung, auf den Dienst zuzugreifen."])}
    },
    "orders/updateMembersInOrders": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellmitglieder erfolgreich aktualisiert."])},
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keine Berechtigung, auf den Dienst zuzugreifen."])}
    },
    "orders/removeGroupFromOrder": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Gruppe war aus dem Orden gestrichen worden."])},
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keine Berechtigung, auf den Dienst zuzugreifen."])}
    },
    "orders/removeMemberFromOrder": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Mitglied war aus dem Orden gestrichen worden."])},
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keine Berechtigung, auf den Dienst zuzugreifen."])}
    },
    "orders/uploadModel": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das 3D-Modell wurde erfolgreich hochgeladen."])},
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keine Berechtigung, auf den Dienst zuzugreifen."])}
    },
    "orders/addProduct": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Produkt wurde erfolgreich hinzugefügt."])},
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben nicht die Berechtigung, auf den Dienst zuzugreifen."])},
      "409": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Versionsname existiert bereits."])}
    },
    "orders/updateContract": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrag erfolgreich aktualisiert."])},
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis kann nur von einem Administrator aktualisiert werden."])}
    },
    "orders/deleteProduct": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlaubnis verweigert."])},
      "405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Produkt kann nicht gelöscht werden, wenn es sich in einem übergebenen Zustand befindet."])}
    },
    "groups/createGroup": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe erfolgreich erstellt."])},
      "409": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Gruppenname existiert bereits."])}
    },
    "groups/deleteGroups": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe(n) erfolgreich gelöscht."])},
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keine Berechtigung, auf den Dienst zuzugreifen."])}
    },
    "groups/addMembersToGroups": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das/die Mitglied(er) wurde(n) erfolgreich hinzugefügt."])},
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keine Berechtigung, auf den Dienst zuzugreifen."])}
    },
    "groups/removeMembersFromGroups": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das/die Mitglied(er) in der/den Gruppe(n) wurde(n) erfolgreich gelöscht."])},
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keine Berechtigung, auf den Dienst zuzugreifen."])}
    },
    "accounts/deleteAccounts": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto (Konten) erfolgreich gelöscht."])},
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keine Berechtigung, auf den Dienst zuzugreifen."])}
    },
    "accounts/removeGroupFromAccount": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Gruppe wurde aus dem Konto entfernt."])},
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keine Berechtigung, auf den Dienst zuzugreifen."])}
    },
    "auth/updateUserProfile": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsches Passwort."])},
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich aktualisiert."])}
    }
  }
}