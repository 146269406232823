import { createApp } from "vue";
import App from "./App.vue";
import { store } from "./store";
import router from "./router";
import "./index.css";
import { i18n } from "./i18n";
// import production from "./config/production.json";
import development from "./config/development.json";
import { BrowserOptions } from "@sentry/browser";
import * as Sentry from "@sentry/vue";

const app = createApp(App);

Sentry.init({
  app,
  environment: process.env.NODE_ENV,
  release: process.env.VUE_APP_RELEASE_VERSION,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [
        // "localhost",
        "https//development.casemanager.rooom.com",
        "https//casemanager.rooom.com",
        /^\//,
      ],
    }),
  ],
  debug: process.env.NODE_ENV !== "production",
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.NODE_ENV === "production" ? 0.2 : 1,
  tracingOptions: {
    trackComponents: true,
  },
  logErrors: process.env.NODE_ENV === "production" ? false : true,
  attachProps: true,
  attachStacktrace: true,
});
// TODO: add separate production config
// if (process.env.NODE_ENV === "production") {
//   app.config.globalProperties.$config = Object.freeze(production);
// } else {
//   app.config.globalProperties.$config = Object.freeze(development);
// }
app.config.globalProperties.$config = Object.freeze(development);
app.use(i18n).use(store).use(router).mount("#app");
