<template>
  <router-view></router-view>
  <the-alert
    v-if="alertOpen"
    :type="alertType"
    :message="alertMessage"
    :closeButtonExist="alertCloseButtonExist"
    :disappearAfter="alertDisappearAfter"
  />
</template>

<script lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
import TheAlert from "@/components/TheAlert.vue";
import { useI18n } from "vue-i18n";
export default {
  components: {
    TheAlert,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    // Alert
    const alertOpen = computed(() => {
      return store.state.alert.open;
    });
    const alertType = computed(() => {
      return store.state.alert.type;
    });
    const alertMessage = computed(() => {
      return store.state.alert.message;
    });
    const alertCloseButtonExist = computed(() => {
      return store.state.alert.closeButtonExist;
    });
    const alertDisappearAfter = computed(() => {
      return store.state.alert.disappearAfter;
    });

    return {
      t,
      alertOpen,
      alertType,
      alertMessage,
      alertCloseButtonExist,
      alertDisappearAfter,
    };
  },
};
</script>

<style lang="scss">
@import url("https://rsms.me/inter/inter.css");
@import url("https://fonts.googleapis.com/css2?family=Baloo+Tammudu+2:wght@400;500;600;700;800&display=swap");

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.baloo-font {
  @apply text-rooom-default text-2xl font-semibold;
  font-family: "Baloo Tammudu 2", cursive;
}
</style>
