<template>
  <span
    :class="[
      role === 'admin' && !darkMode
        ? 'bg-gray-600 text-white'
        : role === 'admin' && darkMode
          ? 'bg-gray-100 text-gray-600'
          : ['manager', 'default_manager'].includes(role) && !darkMode
            ? 'bg-rooom-light text-white'
            : ['manager', 'default_manager'].includes(role) && darkMode
              ? 'bg-rooom-lightest text-rooom-default'
              : role === 'partner' && !darkMode
                ? 'bg-amber-500 text-white'
                : role === 'partner' && darkMode
                  ? 'bg-amber-100 text-amber-500'
                  : role === 'customer' && !darkMode
                    ? 'bg-violet-500 text-white'
                    : 'bg-violet-100 text-violet-500',
      'whitespace-nowrap inline-flex items-center px-2 py-0.5 rounded-full text-xs font-semibold',
    ]"
  >
    {{ role === "default_manager" ? "default manager" : $t(role) }}
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "RoleBadge",
  props: {
    role: {
      type: String,
      default: "",
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
