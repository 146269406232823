<template>
  <a
    href="#"
    v-if="account && account.email"
    @click="onClick"
    :data-tippy-content="account.email"
    :id="`profile-icon-${iconCount}`"
    :class="clickEnabled ? 'cursor-pointer' : 'pointer-events-none'"
  >
    <div
      v-if="
        (!account.image && refreshCount <= 0) ||
        (imageError && refreshCount <= 0) ||
        limitInfo
      "
      :class="[
        account.role === 'admin'
          ? 'bg-gray-600 ring-gray-400'
          : ['manager', 'default_manager'].includes(account.role)
            ? 'bg-rooom-light ring-rooom-lightest'
            : account.role === 'customer'
              ? 'bg-violet-500 ring-violet-300'
              : 'bg-amber-500 ring-amber-300',
        size < 10 ? ' ring-2' : ' ring-4',
        size === 8 ? 'w-7 h-7' : size === 10 ? 'w-9 h-9' : 'w-11 h-11',
        'flex items-center justify-center rounded-full',
      ]"
    >
      <span
        v-if="limitInfo"
        :class="[
          size < 10 ? 'text-sm' : size < 12 ? 'text-base' : 'text-lg',
          'text-sm font-medium leading-none text-white uppercase',
        ]"
        >{{ $t(account.role)[0] }}</span
      >
      <span
        v-else-if="account.first_name"
        :class="[
          size < 10 ? 'text-sm' : size < 12 ? 'text-base' : 'text-lg',
          'text-sm font-medium leading-none text-white uppercase',
        ]"
        >{{ account.first_name[0] + account.last_name[0] }}</span
      >
    </div>
    <img
      v-else
      :class="[
        account.role === 'admin'
          ? ' ring-gray-500'
          : ['manager', 'default_manager'].includes(account.role)
            ? ' ring-rooom-lighter'
            : account.role === 'customer'
              ? ' ring-violet-400'
              : ' ring-amber-400',
        size < 10 ? ' ring-2' : ' ring-4',
        `h-${size - 1} w-${size - 1} max-w-none rounded-full object-cover`,
      ]"
      :src="imageSource.replace('http://', 'https://')"
      @error="onImageError"
      :alt="account.last_name"
    />
  </a>
  <a
    v-else
    href="#"
    :data-tippy-content="$t('deleted-account')"
    :id="`profile-icon-${iconCount}`"
  >
    <div
      :class="[
        size < 10 ? ' ring-2' : ' ring-4',
        `h-${size - 1} w-${
          size - 1
        } inline-flex items-center justify-center rounded-full bg-gray-700 ring-gray-900`,
      ]"
    />
  </a>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Account } from "@/types/accounts.interface";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";

export default defineComponent({
  name: "ProfileIcon",
  props: {
    account: {
      type: Object as () => Account,
    },
    size: {
      type: Number,
      default: 8,
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
    refreshCount: {
      type: Number,
      default: 0,
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
    clickEnabled: {
      type: Boolean,
      default: true,
    },
    limitInfo: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter();
    const imageError = ref(false);
    const store = useStore();
    store.commit("incrementProfileIconCount");
    const iconCount = ref(store.state.profileIconCount);

    onMounted(() => {
      if (props.showTooltip && !props.limitInfo) {
        tippy(`#profile-icon-${iconCount.value}`, {
          arrow: true,
          theme: "light",
        });
      }
    });

    function onImageError() {
      imageError.value = true;
    }
    function onClick() {
      if (props.clickEnabled) {
        router.push(`/user/accounts/${props.account?.id}`);
      }
    }
    const imageSource = computed(() => {
      return props.account?.image + `?rand=${props.refreshCount}`;
    });
    return {
      imageSource,
      onImageError,
      imageError,
      onClick,
      iconCount,
    };
  },
});
</script>
