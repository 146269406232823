export default {
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])},
  "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "dont-know": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't Know"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
  "freely-modelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freely Modelled"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
  "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members"])},
  "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate"])},
  "deactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate"])},
  "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dismiss"])},
  "select-groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Groups"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "last-updated-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Updated on"])},
  "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate"])},
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
  "by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by"])},
  "created-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
  "error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred."])},
  "new-changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Changelog"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
  "update-changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Changelog"])},
  "last-modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Modified"])},
  "last-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Created"])},
  "email-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
  "forgot-your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
  "register-as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register As"])},
  "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
  "position-at-the-company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position at the company"])},
  "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
  "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "second-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Phone"])},
  "phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
  "second-phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Phone Number"])},
  "profile-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
  "confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "as-a-customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["as a customer"])},
  "as-a-partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["as a partner"])},
  "as-a-admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["as an admin"])},
  "as-a-manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["as a manager"])},
  "email-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is required."])},
  "first-name-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name is required."])},
  "last-name-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name is required."])},
  "password-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is required."])},
  "passwords-must-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords must match."])},
  "password must be at least 8 characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 8 characters."])},
  "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["admin"])},
  "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partner"])},
  "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["manager"])},
  "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["customer"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "upload-an-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload an image"])},
  "upload-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload files"])},
  "or-drag-and-drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or drag and drop"])},
  "up-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["up to"])},
  "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["optional"])},
  "forgot-password-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the email address you registered with. We will send you a link to reset the password."])},
  "reset-your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset your password"])},
  "check-your-inbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your inbox"])},
  "back-to-login-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Login Page"])},
  "forgot-password-response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks! If the email was found registered, you will receive an email to reset your password."])},
  "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend"])},
  "reset-your-password-for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset your password for"])},
  "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "reset-password-success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password has been successfully reset."])},
  "reset-password-link-expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This reset link is expired."])},
  "reset-password-already-reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have already reset the password."])},
  "reset-password-unauthorized-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized Access"])},
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
  "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts"])},
  "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
  "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "open-sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open sidebar"])},
  "close-sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close sidebar"])},
  "new-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Order"])},
  "pending-banner-message-main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please update your profile in the Settings menu for further use."])},
  "pending-banner-message-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please update your profile."])},
  "approving-banner-message-main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for updating your profile. Please wait until it's approved by us soon."])},
  "approving-banner-message-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile will be approved soon."])},
  "go-to-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Settings"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adress"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street and House Number"])},
  "notification-setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Setting"])},
  "send-email-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Email Notifications"])},
  "send-email-notifications-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When enabled, you will get emails on our feature updates."])},
  "partner-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner Information"])},
  "company-size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Size"])},
  "partner-tags-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expertise Tags"])},
  "partner-tags-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add tags for your field of expertise"])},
  "language-setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language Setting"])},
  "app-setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Setting"])},
  "payment-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method"])},
  "no-payment-method-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Payment Method Set"])},
  "input-value-must-be-valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input value must be valid."])},
  "update-user-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update User Role"])},
  "update-first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update First Name"])},
  "update-last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Last Name"])},
  "update-phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Phone Number"])},
  "update-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Website"])},
  "update-position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Position"])},
  "update-profile-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Profile Image"])},
  "update-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Description"])},
  "update-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Country"])},
  "update-city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update City"])},
  "update-postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Postcode"])},
  "update-street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Street and House Number"])},
  "choose-a-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a country"])},
  "update-iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update IBAN"])},
  "update-company-size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Company Size"])},
  "add-a-new-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new tag"])},
  "auth-getStripeApiKey-412": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong with payment method setup."])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
  "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
  "current-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
  "confirm-new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm New Password"])},
  "password-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 8 characters."])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "fix-sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fix sidebar"])},
  "search-tags-to-filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search tags to filter"])},
  "search-members-to-filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search members to filter"])},
  "Filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
  "Showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing"])},
  "Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
  "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
  "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["results"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new"])},
  "order-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Token"])},
  "managers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managers"])},
  "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
  "assign-members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Members"])},
  "search-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Orders"])},
  "last-ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Ordered"])},
  "id-(a-z)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID (A-Z)"])},
  "id-(z-a)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID (Z-A)"])},
  "order-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Date"])},
  "contractor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contractor"])},
  "orders-delete-panel-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete these orders?"])},
  "ordered-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordered By"])},
  "search-for-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for accounts"])},
  "search-for-accounts-or-groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for accounts or groups"])},
  "deleted-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted Account"])},
  "our-price-offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Price Offer"])},
  "Filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtered"])},
  "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "Exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclude"])},
  "Include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Include"])},
  "Archive Only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive Only"])},
  "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inactive"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pending"])},
  "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["approved"])},
  "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["draft"])},
  "submitted_incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["submitted_incomplete"])},
  "submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["submitted"])},
  "offering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offering"])},
  "negotiating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["negotiating"])},
  "contracting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contracting"])},
  "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["processing"])},
  "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reviewing"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["completed"])},
  "payment_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment failed"])},
  "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["canceled"])},
  "incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["incomplete"])},
  "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])},
  "archive-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive Order"])},
  "unarchive-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unarchive Order"])},
  "cancel-reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for Cancelation"])},
  "accepted, awaiting payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accepted, awaiting payment"])},
  "Registration confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration confirmed"])},
  "Thanks for your registration. You are now ready to login.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for your registration. You are now ready to login."])},
  "You have already confirmed the registration.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have already confirmed the registration."])},
  "Invalid access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid access"])},
  "No registration request found with this email.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No registration request found with this email."])},
  "add-members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Members"])},
  "assign-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Orders"])},
  "search-groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search groups"])},
  "search-for-groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search groups"])},
  "groups-delete-panel-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete these groups?"])},
  "groups-get-alert-403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups can only be accessed by an admin."])},
  "name-(a-z)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name (A-Z)"])},
  "name-(z-a)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name (Z-A)"])},
  "new-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Group"])},
  "add-a-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a member"])},
  "group-create-alert-200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A group has successfully been created."])},
  "group-create-alert-403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups can only be created by an admin."])},
  "select-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Accounts"])},
  "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign"])},
  "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["required"])},
  "set-as-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set as default"])},
  "account-activate-button-tippy-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate the account without the user's email confirmation."])},
  "email-confirmation-bypass-checkbox-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate the account without email confirmation."])},
  "login-post-401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong credentials."])},
  "login-post-403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete your registration via the confirmation link we've sent to your email address."])},
  "login-post-405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banned account."])},
  "accounts-create-alert-200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An account has successfully been created."])},
  "register-post-200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for your registration! Please confirm your registration by following the link we've sent to your email address."])},
  "register-post-409": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email already in use."])},
  "register-put-200-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration confirmed"])},
  "register-put-200-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for your registration. You are now ready to login."])},
  "register-put-404-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid access"])},
  "register-put-404-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No registration request found with this email."])},
  "register-put-409-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration confirmed"])},
  "register-put-409-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have already confirmed the registration."])},
  "register-put-419-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired Link"])},
  "register-put-419-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops. It's been too long. Please register again."])},
  "add-to-groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Groups"])},
  "search-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search accounts"])},
  "accounts-add-admin-error-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin account cannot be assigned to groups."])},
  "accounts-delete-panel-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete these accounts?"])},
  "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve"])},
  "disapprove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disapprove"])},
  "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban"])},
  "unban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unban"])},
  "q-cad-have-color-texture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does your CAD data have color and/or texture information?"])},
  "q-addition-object-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have additional object images?"])},
  "q-fm-have-texture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have texture information?"])},
  "q-fm-have-pbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are PBR Textures available? (e.g. base color, metalness, roughness)"])},
  "new-accessible-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Accessible Orders"])},
  "add-an-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an order"])},
  "group-delete-panel-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this group?"])},
  "new-group-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Group Name"])},
  "new-group-color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Group Color"])},
  "group-update-200-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A group has been successfully updated."])},
  "group-update-403-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A group can only be updated by an admin."])},
  "group-delete-200-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A group has been successfully deleted."])},
  "search-for-members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for members"])},
  "accessible-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessible Orders"])},
  "search-for-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for orders"])},
  "switch-to-processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to processing"])},
  "switch-to-reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to reviewing"])},
  "changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["changed to"])},
  "with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with"])},
  "marked-as-internal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marked as Internal"])},
  "start-processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start processing"])},
  "set-frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Frequency"])},
  "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification"])},
  "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On"])},
  "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Off"])},
  "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
  "every-3-days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every 3 Days"])},
  "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])},
  "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
  "markup-product-snapshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markup product snapshot"])},
  "markup-snapshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a snapshot"])},
  "new-change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new change"])},
  "new-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new changes"])},
  "new-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new message"])},
  "new-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new messages"])},
  "no-new-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no new messages."])},
  "update-payment-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your previous payment attempt failed. Please update your card in Settings."])},
  "update-payment-method-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update your payment method."])},
  "d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d"])},
  "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
  "update-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Title"])},
  "update-rooom-object_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Rooom Object Id"])},
  "final-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Price"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "confirm-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Payment"])},
  "payment-confirm-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After the final version of the product is selected, your saved card will be charged."])},
  "payment-confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment successfully confirmed."])},
  "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
  "add-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add files"])},
  "update-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Product"])},
  "no-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No products"])},
  "new-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Product"])},
  "pass-for-customer-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass for customer review"])},
  "add-new-version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new version"])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
  "mark-contracts-as-signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark contracts as signed"])},
  "mark-as-signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as signed"])},
  "contract-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The contract uploaded here eventually has to be signed by the both parties."])},
  "update-contract-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Contract Files"])},
  "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
  "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deselect"])},
  "your-offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Offer"])},
  "update-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Price"])},
  "update-delivery-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Delivery Date"])},
  "open-for-offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open for offers"])},
  "cancel-the-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel the order"])},
  "close-the-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close the order"])},
  "scan-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D Scan Information"])},
  "update-scan-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Scan Files"])},
  "update-product-viewer-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Product Viewer ID"])},
  "show-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
  "mark-as-ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as ready"])},
  "product-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Information"])},
  "customer-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Description"])},
  "update-customer-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Customer Description"])},
  "customer-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Files"])},
  "update-customer-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Customer Files"])},
  "custom-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Field"])},
  "due-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due at"])},
  "order-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Profile"])},
  "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
  "update-due-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Due Date"])},
  "update-reference-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Reference ID"])},
  "update-budget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Budget"])},
  "update-tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Tags"])},
  "update-animation-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Animation Description"])},
  "price-update-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This update will affect the price."])},
  "payment-successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Successful"])},
  "go-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])},
  "payment-canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Canceled"])},
  "billing-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Details"])},
  "card-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Number"])},
  "card-expiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Expiry"])},
  "billing-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Country"])},
  "postal-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
  "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
  "Order placed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order placed"])},
  "Case opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case opened"])},
  "Offer submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer submitted"])},
  "Offer accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer accepted"])},
  "Product uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product uploaded"])},
  "Product approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product approved"])},
  "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
  "make-a-comment-public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a public comment"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "add-a-comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a comment"])},
  "offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offers"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "delivery-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Date"])},
  "deleted-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted User"])},
  "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accepted"])},
  "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["declined"])},
  "new-offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Offer"])},
  "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local"])},
  "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer"])},
  "price-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price is required."])},
  "past-date-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due date must be set in the future."])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
  "ordered-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordered on"])},
  "close-the-case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close the case"])},
  "order-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Details"])},
  "3d-data-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D Data Type"])},
  "level-of-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level of Detail"])},
  "level-of-detail-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How detailed should your 3D model be displayed?"])},
  "inner-structure-exists-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does your object have an inner life?"])},
  "logos-included-in-the-object": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logos Included in the Object"])},
  "logo-description-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Logo Description"])},
  "color-description-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Color Description"])},
  "text-description-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Text Description"])},
  "special-details-description-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Special Details Description"])},
  "first-name-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update First Name"])},
  "last-name-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Last Name"])},
  "position-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Position"])},
  "email-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Email"])},
  "phone-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Phone Number"])},
  "second-phone-edit-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Second Phone Number"])},
  "inner-structure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inner Structure"])},
  "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exists"])},
  "undecided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undecided"])},
  "3d-data-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D Data Files"])},
  "(with color and/or texture)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(with color and/or texture)"])},
  "(with PBR texture)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(with PBR texture)"])},
  "(with texture)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(with texture)"])},
  "upload-new-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload New File"])},
  "Files with Color Representation (e.g. sketches, drawings)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files with Color Representation (e.g. sketches, drawings)"])},
  "object-image-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object Image Files"])},
  "color-authenticity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color Authenticity"])},
  "displaying-texts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Displaying Texts"])},
  "special-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Details"])},
  "contact-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Details"])},
  "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
  "open-the-case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open the case"])},
  "make-an-offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make an offer"])},
  "upload-a-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a product"])},
  "finalize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalize"])},
  "re-upload-a-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-upload a product"])},
  "approve-&-pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve & Pay"])},
  "reopen-the-case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reopen the case"])},
  "orders-form-update-200-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order details successfully updated."])},
  "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple"])},
  "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium"])},
  "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complex"])},
  "close-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close details"])},
  "open-full-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open full details"])},
  "thanks-for-your-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for your Order!"])},
  "you-can-view-your-order-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view your order details"])},
  "you-can-view-your-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view your orders"])},
  "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
  "Please check your email to set a password for your account on our": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check your email to set a password for your account on our"])},
  "Order Your 3D Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Your 3D Model"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["back"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next"])},
  "How detailed should your 3D model be displayed?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How detailed should your 3D model be displayed?"])},
  "The desired level of detail influences the price.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The desired level of detail influences the price."])},
  "lod-simple-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A description for Simple."])},
  "lod-medium-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A description for Medium."])},
  "lod-complex-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A description for Complex"])},
  "Does your object have an inner life?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does your object have an inner life?"])},
  "Objects with an inner life has to be web-enabled, thus more difficult to display.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objects with an inner life has to be web-enabled, thus more difficult to display."])},
  "Inner Life": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inner Life"])},
  "Upload pictures of your object in all directions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload pictures of your object in all directions"])},
  "The image qualities may influence the quality of the final 3D model.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The image qualities may influence the quality of the final 3D model."])},
  "If there are 3D specialists employed at your company, they can be referred here.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there are 3D specialists employed at your company, they can be referred here."])},
  "Upload Additional Data of the Object": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Additional Data of the Object"])},
  "This information speeds up the modeling process and ensures an accurate representation of your object.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This information speeds up the modeling process and ensures an accurate representation of your object."])},
  "Logos Included in the Object": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logos Included in the Object"])},
  "Color Authenticity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color Authenticity"])},
  "Displaying Texts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Displaying Texts"])},
  "Special Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Details"])},
  "Upload Your Object Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Your Object Data"])},
  "Here you can upload your 3D Data, either in CAD format or freely-modelled.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can upload your 3D Data, either in CAD format or freely-modelled."])},
  "Do you already have a 3D data?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you already have a 3D data?"])},
  "What type is your 3D data?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What type is your 3D data?"])},
  "3D Data Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D Data Type"])},
  "Upload your 3D data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload your 3D data"])},
  "with PBR Textures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with PBR Textures"])},
  "with the texture information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with the texture information"])},
  "Are PBR Textures available? (e.g. base color, metalness, roughness)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are PBR Textures available? (e.g. base color, metalness, roughness)"])},
  "Do you have texture information?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have texture information?"])},
  "Upload data with color represenation (e.g. sketches, drawings)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload data with color represenation (e.g. sketches, drawings)"])},
  "Do you have additional object images?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have additional object images?"])},
  "Does your CAD data have color and/or texture information?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does your CAD data have color and/or texture information?"])},
  "Upload your CAD data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload your CAD data"])},
  "e.g. The desired format is .png with transparency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. The desired format is .png with transparency"])},
  "e.g. Font names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. Font names"])},
  "e.g. Display contents such as pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. Display contents such as pictures"])},
  "e.g. Any sketches or design data (RGB or RAL)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. Any sketches or design data (RGB or RAL)"])},
  "position-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position is required."])},
  "email must be a valid email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email must be a valid email address."])},
  "Offer unaccepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer unaccepted"])},
  "Product re-uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product re-uploaded"])},
  "to-login-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Login Page"])},
  "Customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
  "Customer Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Accounts"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
  "draft-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When checked, the order will not be processed until you mark it as ready."])},
  "which-order-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Order Method"])},
  "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form"])},
  "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
  "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
  "order-form-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in and submit."])},
  "order-file-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload an excel file and related files."])},
  "example-excel-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["example-excel-file.xlsx"])},
  "order-link-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share a link to the folder on your cloud storage."])},
  "order-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order"])},
  "reference-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference ID"])},
  "order-form-reference-id-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An ID to be used for searching the order."])},
  "animation-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation Required?"])},
  "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])},
  "due-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])},
  "due-date-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due date must be in the future."])},
  "budget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budget"])},
  "custom-tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Tags"])},
  "order-form-tags-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags can be useful for searching and filtering your orders."])},
  "file-size-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The total size of files exceeds the limit."])},
  "mark-as-draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as draft"])},
  "new-custom-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Custom Field"])},
  "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
  "custom-fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Fields"])},
  "order-form-custom-fields-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add your own key-value set to customize your order profile."])},
  "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key"])},
  "animation-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation Description"])},
  "mark-as-internal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as internal"])},
  "mark-as-internal-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When checked, the order will be processed in a simpler flow, bypassing partner offers and contracts section."])},
  "new-order-popup-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit New Order"])},
  "new-order-popup-description-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to our Product Enhancement Order Form!"])},
  "new-order-popup-description-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unhappy with our digital product? Share your input on how we can improve. Expect your custom enhancement offer within 3 business days, along with an estimated timeline for the final reseult."])},
  "new-order-popup-description-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. The result is not what I expected..."])},
  "order-overview-popup-submitted-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you! Your enhancement request has been received. Expect our feedback and cost estimate within 3 business days. We appreciate your input in making our product better!"])},
  "Order archived.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order archived."])},
  "Order unarchived.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order unarchived."])},
  "Updated the due date.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated the due date."])},
  "Updated the reference ID.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated the reference ID."])},
  "Updated the budget.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated the budget."])},
  "Updated the tags.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated the tags."])},
  "Updated the customer description.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated the customer description."])},
  "Updated the animation requirement.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated the animation requirement."])},
  "Marked the order as internal.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marked the order as internal."])},
  "Marked the order as non-internal.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marked the order as non-internal."])},
  "Updated the animation description.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated the animation description."])},
  "Activated the 3d scan information section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated the 3d scan information section."])},
  "Deactivated the 3d scan information section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivated the 3d scan information section."])},
  "Updated the title.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated the title."])},
  "Deleted a customer file.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted a customer file."])},
  "Updated the customer files.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated the customer files."])},
  "Added a new custom field.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added a new custom field."])},
  "Updated a custom field.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated a custom field."])},
  "Removed a custom field.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removed a custom field."])},
  "Updated the description for 3d scan information.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated the description for 3d scan information."])},
  "Deleted a scan file.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted a scan file."])},
  "Disconnect rooom files from the Case Manager order.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect rooom files from the Case Manager order."])},
  "Disconnect the rooom file from the Case Manager order.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect the rooom file from the Case Manager order."])},
  "Updated the 3d scan files.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated the 3d scan files."])},
  "Updated the product viewer ID for 3d scan data.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated the product viewer ID for 3d scan data."])},
  "Selected an offer.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected an offer."])},
  "Deselected an offer.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deselected an offer."])},
  "Canceled the order.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled the order."])},
  "Marked the order as ready.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marked the order as ready."])},
  "Marked the order as draft.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marked the order as draft."])},
  "Opened the case for offers.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opened the case for offers."])},
  "Updated the order status to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated the order status to"])},
  "Offer unaccepted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer unaccepted."])},
  "Case opened.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case opened."])},
  "Offer accepted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer accepted."])},
  "Product approved.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product approved."])},
  "Submitted an offer.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted an offer."])},
  "Removed the offer.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removed the offer."])},
  "Updated the offer.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated the offer."])},
  "Payment completed.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment completed."])},
  "Confirmed the payment.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed the payment."])},
  "Payment has succesfully done.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment has succesfully done."])},
  "Payment has failed.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment has failed."])},
  "Updated the contract price.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated the contract price."])},
  "Updated the contract files.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated the contract files."])},
  "Added a new product.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added a new product."])},
  "Deleted the product.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted the product."])},
  "Selected the final product version.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected the final product version."])},
  "Selected the final product version and confirmed the payment.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected the final product version and confirmed the payment."])},
  "Changed the final product version.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changed the final product version."])},
  "Updated a product status.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated a product status."])},
  "Accepted the offer.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted the offer."])},
  "Updated the product title.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated the product title."])},
  "Updated the product viewer ID.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated the product viewer ID."])},
  "Updated the product description.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated the product description."])},
  "Updated product files.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated product files."])},
  "The customer declined the offer, canceling the order.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The customer declined the offer, canceling the order."])},
  "Privacy Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "Disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disclaimer"])},
  "Terms and Conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
  "alerts": {
    "orders/placeOrder": {
      "400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order failed due to an invalid request."])},
      "406": {
        "excel-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred at the following entry: "])}
      }
    },
    "orders/deleteOrders": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order(s) successfully deleted."])},
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have the permission to access the service."])}
    },
    "orders/updateMembersInOrders": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order members have successfully been updated."])},
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have the permission to access the service."])}
    },
    "orders/removeGroupFromOrder": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The group had been removed from the order."])},
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have the permission to access the service."])}
    },
    "orders/removeMemberFromOrder": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The member had been removed from the order."])},
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have the permission to access the service."])}
    },
    "orders/uploadModel": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The 3D model has successfully been uploaded."])},
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have the permission to access the service."])}
    },
    "orders/addProduct": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The product successfully added."])},
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have the permission to access the service."])},
      "409": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The version name already exists."])}
    },
    "orders/updateContract": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract successfully updated."])},
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price can only be updated by an admin."])}
    },
    "orders/deleteProduct": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission denied."])},
      "405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product cannot be deleted when it's in a passed state."])}
    },
    "groups/createGroup": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group successfully created."])},
      "409": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The group name already exists."])}
    },
    "groups/deleteGroups": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group(s) successfully deleted."])},
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have the permission to access the service."])}
    },
    "groups/addMembersToGroups": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member(s) have successfully been added."])},
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have the permission to access the service."])}
    },
    "groups/removeMembersFromGroups": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member(s) in group(s) have successfully been deleted."])},
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have the permission to access the service."])}
    },
    "accounts/deleteAccounts": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account(s) successfully deleted."])},
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have the permission to access the service."])}
    },
    "accounts/removeGroupFromAccount": {
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group had been removed from the account."])},
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have the permission to access the service."])}
    },
    "auth/updateUserProfile": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong password."])},
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully updated."])}
    }
  }
}