import { Module } from "vuex";
import { RootState } from "@/store/index";
import axios from "axios";

const API_URL = process.env.VUE_APP_BACKEND_URL + "/";

interface Accounts {
  accounts: any[];
  updated: boolean;
  selectedAccounts: any[];
}

const accounts: Module<Accounts, RootState> = {
  namespaced: true,
  state: {
    accounts: [],
    updated: false,
    selectedAccounts: [],
  },
  mutations: {
    setAccounts(state, accounts: any[]) {
      state.accounts = accounts;
    },
    setUpdated(state, updated: boolean) {
      state.updated = updated;
    },
    setSelectedAccounts(state, accounts: any[]) {
      state.selectedAccounts = accounts;
    },
  },
  actions: {
    searchAccounts({ dispatch, rootGetters }, params: any) {
      return axios
        .get(
          API_URL +
            `search_accounts?q=${params.query}&sortBy=${params.sort}&filterOut=${params.filterOut}`,
          {
            headers: rootGetters["auth/authHeader"],
          },
        )
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "accounts/searchAccounts",
              callbackActionArgs: params,
            },
            { root: true },
          );
        });
    },
    getAccounts({ state, dispatch, rootGetters }, params: any) {
      return axios
        .get(
          API_URL +
            `accounts?q=${params.query}&sortBy=${params.sort}&filterOut=${params.filterOut}`,
          {
            headers: rootGetters["auth/authHeader"],
          },
        )
        .then((response) => {
          state.accounts = response.data.accounts;
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "accounts/getAccounts",
              callbackActionArgs: params,
            },
            { root: true },
          );
        });
    },
    deleteAccounts({ commit, dispatch, rootGetters }, accountEmails: string[]) {
      return axios
        .delete(API_URL + "accounts", {
          headers: rootGetters["auth/authHeader"],
          data: { accountEmails: accountEmails },
        })
        .then((response) => {
          commit(
            "openAlert",
            {
              type: "success",
              message: "alerts.accounts/deleteAccounts.200",
              disappearAfter: 3000,
              closeButtonExist: false,
            },
            { root: true },
          );
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "accounts/deleteAccounts",
              callbackActionArgs: accountEmails,
            },
            { root: true },
          );
        });
    },
    removeGroupFromAccount({ commit, dispatch, rootGetters }, payload: any) {
      return axios
        .delete(API_URL + "accounts/group", {
          headers: rootGetters["auth/authHeader"],
          data: payload,
        })
        .then((response) => {
          commit(
            "openAlert",
            {
              type: "success",
              message: "alerts.accounts/removeGroupFromAccount.200",
              disappearAfter: 3000,
              closeButtonExist: false,
            },
            { root: true },
          );
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "accounts/removeGroupFromAccount",
              callbackActionArgs: payload,
            },
            { root: true },
          );
        });
    },
    getAccount({ dispatch, rootGetters }, accountId: number) {
      return axios
        .get(API_URL + `account/${accountId}`, {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "accounts/getAccount",
              callbackActionArgs: accountId,
            },
            { root: true },
          );
        });
    },
    removeProfileImage({ dispatch, rootGetters }, accountId: string) {
      return axios
        .delete(API_URL + `account/${accountId}/image`, {
          headers: rootGetters["auth/authHeader"],
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          return await dispatch(
            "handleError",
            {
              error: error,
              callbackAction: "accounts/removeProfileImage",
              callbackActionArgs: accountId,
            },
            { root: true },
          );
        });
    },
  },
  getters: {},
};

export default accounts;
