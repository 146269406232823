<template>
  <div
    v-if="open"
    :class="[
      type === 'error'
        ? 'bg-red-600'
        : type === 'warning'
          ? 'bg-amber-600'
          : type === 'success'
            ? 'bg-emerald-600'
            : 'bg-blue-600',
    ]"
  >
    <div class="max-w-full mx-auto py-3 px-3 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between flex-wrap">
        <div class="w-0 flex-1 flex items-center">
          <span
            :class="[
              type === 'error'
                ? 'bg-red-800'
                : type === 'warning'
                  ? 'bg-amber-800'
                  : type === 'success'
                    ? 'bg-emerald-800'
                    : 'bg-blue-800',
              'flex p-2 rounded-lg',
            ]"
          >
            <ExclamationCircleIcon
              v-if="type === 'error'"
              class="h-6 w-6 text-white"
            />
            <MegaphoneIcon v-else class="h-6 w-6 text-white" />
          </span>
          <p class="ml-3 font-medium text-white truncate">
            <span class="md:hidden">
              {{ $t(shortText ? shortText : mainText) }}
            </span>
            <span class="hidden md:inline">
              {{ $t(mainText) }}
            </span>
          </p>
        </div>
        <div
          v-if="linkText"
          class="order-3 mt-2 shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto"
        >
          <router-link
            :to="link"
            :class="[
              type === 'error'
                ? 'text-red-600 hover:bg-red-50'
                : type === 'warning'
                  ? 'text-amber-600 hover:bg-amber-50'
                  : type === 'success'
                    ? 'text-emerald-600 hover:bg-emerald-50'
                    : 'text-blue-600 hover:bg-blue-50',
              'flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium bg-white',
            ]"
          >
            {{ $t(linkText) }}
          </router-link>
        </div>
        <div class="order-2 shrink-0 sm:order-3 sm:ml-3">
          <button
            type="button"
            @click.prevent="close"
            :class="[
              type === 'error'
                ? 'hover:bg-red-500'
                : type === 'warning'
                  ? 'hover:bg-amber-500'
                  : type === 'success'
                    ? 'hover:bg-emerald-500'
                    : 'hover:bg-blue-500',
              '-mr-1 flex p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2',
            ]"
          >
            <span class="sr-only">Dismiss</span>
            <XMarkIcon class="w-6 h-6 text-white" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import {
  ExclamationCircleIcon,
  MegaphoneIcon,
  XMarkIcon,
} from "@heroicons/vue/24/outline";

export default defineComponent({
  name: "TopBanner",
  components: {
    ExclamationCircleIcon,
    MegaphoneIcon,
    XMarkIcon,
  },
  setup() {
    const store = useStore();
    const open = computed(() => store.state.banner.open);
    const type = computed(() => store.state.banner.type);
    const mainText = computed(() => store.state.banner.mainText);
    const shortText = computed(() => store.state.banner.shortText);
    const linkText = computed(() => store.state.banner.linkText);
    const link = computed(() => store.state.banner.link);

    function close() {
      store.commit("closeBanner", true);
    }

    return {
      open,
      type,
      mainText,
      shortText,
      linkText,
      link,
      close,
    };
  },
});
</script>
