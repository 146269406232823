import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_the_alert = _resolveComponent("the-alert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    ($setup.alertOpen)
      ? (_openBlock(), _createBlock(_component_the_alert, {
          key: 0,
          type: $setup.alertType,
          message: $setup.alertMessage,
          closeButtonExist: $setup.alertCloseButtonExist,
          disappearAfter: $setup.alertDisappearAfter
        }, null, 8, ["type", "message", "closeButtonExist", "disappearAfter"]))
      : _createCommentVNode("", true)
  ], 64))
}