<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
      <div
        class="inline-block align-bottom bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <form v-if="!emailSent" class="space-y-8">
          <div class="space-y-8">
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                {{ $t("forgot-your-password") }}
              </h3>
              <div class="mt-2 max-w-xl text-sm text-gray-500">
                <p>
                  {{ $t("forgot-password-description") }}
                </p>
              </div>
              <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div class="sm:col-span-6">
                  <label
                    for="email-reset"
                    class="block text-sm font-medium text-gray-700"
                  >
                    {{ $t("email-address") }}
                  </label>
                  <div class="mt-1 relative">
                    <input
                      v-model="email"
                      type="email"
                      name="email-reset"
                      class="shadow-sm focus:ring-rooom-light focus:border-rooom-light block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="py-3 sm:flex sm:flex-row-reverse">
            <button
              @click.prevent="onSend"
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-rooom-default text-base font-medium text-white hover:bg-rooom-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rooom-light sm:ml-3 sm:w-auto sm:text-sm"
            >
              {{ $t("send") }}
            </button>
            <button
              @click.prevent="closePanel"
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rooom-light sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {{ $t("cancel") }}
            </button>
          </div>
        </form>
        <div v-else class="space-y-8">
          <div class="space-y-8">
            <h3 class="mt-2 text-lg leading-4 font-medium text-gray-900">
              {{ $t("check-your-inbox") }}
            </h3>
            <div class="max-w-xl text-sm text-gray-500">
              <p>
                {{ $t("forgot-password-response") }}
              </p>
            </div>
          </div>
          <div class="py-3 sm:flex sm:flex-row-reverse">
            <button
              @click.prevent="onResend"
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-rooom-default text-base font-medium text-white hover:bg-rooom-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rooom-light sm:ml-3 sm:w-auto sm:text-sm"
            >
              {{ $t("resend") }}
            </button>
            <button
              @click.prevent="closePanel"
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rooom-light sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {{ $t("back-to-login-page") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, watch } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "LoginPageForgotPasswordPanel",
  emits: ["closeForgotPasswordPanel"],
  setup(_, { emit }) {
    const store = useStore();
    const email = ref("");
    const emailSent = ref(false);
    const emailError = ref("");

    watch(emailError, (newValue) => {
      if (newValue) {
        document
          .querySelector('input[name="email-reset"]')
          ?.classList.add("error");
      } else {
        document
          .querySelector('input[name="email-reset"]')
          ?.classList.remove("error");
      }
    });

    function onSend() {
      if (email.value === "") return;
      store.dispatch("auth/forgotPassword", email.value).then(
        () => {
          emailSent.value = true;
        },
        (error) => {
          store.commit("openAlert", {
            type: "error",
            message: error.response?.data.message
              ? error.response.data.message
              : error.response?.data
                ? error.response.data
                : error.message
                  ? error.message
                  : "An error occurred",
          });
        },
      );
    }

    function onResend() {
      email.value = "";
      emailSent.value = false;
    }

    function closePanel() {
      emit("closeForgotPasswordPanel");
      emailSent.value = false;
    }

    return {
      emailSent,
      email,
      onSend,
      onResend,
      closePanel,
    };
  },
});
</script>

<style lang="scss" scoped>
input.error {
  @apply border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500;
}
</style>
